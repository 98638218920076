import { RadioGroup } from '@/components/ui/radio-group';
import { Label } from '@radix-ui/react-label';
import clsx from 'clsx';
import { rewardCategorys } from '@/features/campaigns/core/reward-defaults';
import {
	CampaignRewardCategory,
	ICampaignAddReward,
} from '@/features/campaigns/types';
import { Separator } from '@/components/ui/separator';
import { Switch } from '@/components/ui/switch';
import { cn } from '@/lib/utils';
import { Dispatch, SetStateAction } from 'react';
import { useCreateCampaignRewards } from '@/features/campaigns/hooks/create/useCreateCampaignRewards';
import { useAuth } from '@/hooks/useAuth';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';

const AddRewardMethods = ({
	rewards,
	mode,
}: {
	rewards: ICampaignAddReward[];
	mode: string;
}) => {
	const { user } = useAuth();

	const { toggleRewardMethod } = useCreateCampaignRewards();

	return (
		<>
			<div className="mb-4 mt-10">
				<Label className="text-md font-medium ">
					{mode === 'simple'
						? 'Choose one of the following reward you want to add'
						: 'Select rewards you want to add'}
				</Label>
				{/**
				 * commented out as mode doesn't affect rewards
				 */}
				{/* {mode === 'simple' && (
					<div className="mt-1">
						<p className="text-xs ">
							If you want to add multiple rewards, you can do so by
							switching to
							<span className="font-medium ms-1 hover:underline cursor-pointer">
								advance mode
							</span>
						</p>
					</div>
				)} */}
			</div>
			<RadioGroup defaultValue="card" className="grid grid-cols-5 gap-2 ">
				{rewardCategorys.map((i) => (
					<RewardMethod
						{...i}
						checked={
							rewards.find((j) => j.category === i.category)?.isActive
						}
						select={toggleRewardMethod}
						isMultiple={mode === 'advanced'}
						disabled={
							i.category === CampaignRewardCategory.DiscordRole
								? !user.integrationStatus.discord
								: false
						}
						disabledTooltip="Please connect your discord account to enable this reward"
						key={i.category}
					/>
				))}
			</RadioGroup>
			<div className="flex justify-center mt-5">
				<i className="bi bi-arrow-down-circle-fill text-muted-foreground text-2xl"></i>
			</div>
			<Separator className="-mt-4" />
		</>
	);
};

export default AddRewardMethods;

const RewardMethod = ({
	label,
	category,
	description,
	checked,
	select,
	icon,
	disabled,
	disabledTooltip,
}: any) => {
	return (
		<TooltipWrapper tooltip={disabled ? disabledTooltip : ''}>
			<div>
				<div
					className={clsx(
						'bg-white p-2  rounded-lg cursor-pointer border',
						checked ? 'border-black ' : '',
						disabled ? 'opacity-50' : 'hover:border-black',
					)}
					onClick={() => {
						!disabled ? select(category) : '';
					}}
				>
					<div className="flex flex-col">
						<div className="flex justify-between items-center">
							<i
								className={cn(
									`bi bi-${icon}`,
									!checked && 'text-muted-foreground',
									'me-3 block  text-xl',
								)}
							/>
							<Switch
								size="sm"
								checked={checked}
								disabled={disabled}
							/>
						</div>
						<div className="mt-4 text-start">
							<div className="text-sm font-medium">{label}</div>
							{/* <p className="text-xs text-muted-foreground ">
								{description}
							</p> */}
						</div>
					</div>
				</div>
			</div>
		</TooltipWrapper>
	);
};
