import { Input } from '@/components/plate-ui/input';
import { Button } from '@/components/ui/button';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { useConnectAPI } from '../hooks/useConnectAPI';
import { ApiValueKeys } from '../types/task-apis.enum';

export const PayloadItem = ({ setItems, index }) => {
	const { formErrors, formFields, setFormFields, setFormErrors } = useConnectAPI();

	const templateKeyMap = {
		wallet: 'address',
		'twitter-id': 'twitter',
		'twitter-handle': 'twitterHandle',
		'discord-id': 'discord',
		'discord-handle': 'discordHandle',
		'telegram-id': 'telegram',
		email: 'email',
	};

	const handlePayloadChange = (newVal: any) => {
		try {
			const tempApiPayload = [...(formFields?.apiPayload ?? [])];
			tempApiPayload[index] = {
				key: templateKeyMap[newVal] ?? newVal,
				value: newVal,
				testValue: '',
			};

			setFormFields((prev: any) => ({
				...prev,
				apiPayload: tempApiPayload,
			}));
		} catch (err) {
			console.log(err);
		}
	};
	const handleTemplateChange = (e: any) => {
		const { newVal } = e.target;
		setFormErrors((prev: any) => ({
			...prev,
			[formFields?.apiPayload[index]?.key]: '',
		}));
		const tempApiPayload = [...(formFields?.apiPayload ?? [])];
		tempApiPayload[index] = {
			...tempApiPayload[index],
			key: newVal,
		};
		setFormFields((prev: any) => ({
			...prev,
			apiPayload: tempApiPayload,
		}));
	};

	const removePayloadItem = (keyToRemove) => {
		setItems((prevItems) => Math.max(prevItems - 1, 0));
		const updated = formFields.apiPayload.filter(
			(item) => item.key !== keyToRemove,
		);
		setFormFields((prev) => ({
			...prev,
			apiPayload: updated,
		}));
	};

	return (
		<div className="flex gap-2 py-1 px-0.5">
			<div
				className={`flex items-center flex-grow space-x-2 sm:space-x-0 max-w-[13.2rem]`}
			>
				<Select
					onValueChange={(newVal) => handlePayloadChange(newVal)}
					value={formFields?.apiPayload?.[index]?.key}
				>
					<SelectTrigger aria-label="Area" className="text-sm">
						<SelectValue placeholder="Select" className="h-10 " />
					</SelectTrigger>
					<SelectContent>
						{ApiValueKeys?.map((item) => (
							<SelectItem key={item.value} value={item.value}>
								{item.label}
							</SelectItem>
						))}
					</SelectContent>
				</Select>
			</div>
			<div className="flex flex-grow items-center gap-2 max-w-[13.2rem]">
				<Input
					className={`h-9 ${
						formErrors[formFields?.apiPayload?.[index]?.key]
							? 'border-destructive'
							: ''
					}`}
					value={formFields?.apiPayload?.[index]?.value}
					onChange={(e) => handleTemplateChange(e)}
					disabled
				/>
			</div>
			<div className="flex items-center justify-end">
				<Button
					variant="outline"
					size="sm"
					className="w-9 h-9"
					onClick={() =>
						removePayloadItem(formFields?.apiPayload?.[index]?.key)
					}
				>
					<i className="bi bi-x-lg"></i>
				</Button>
			</div>
		</div>
	);
};
