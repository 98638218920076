import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuPortal,
	DropdownMenuSeparator,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useAuth } from '@/hooks/useAuth';
import { Link } from 'react-router-dom';
import CreateEnterpriseDialog from '@/features/onboarding/components/create-enterprise/CreateEnterprise';
import { useState } from 'react';
import { useSwitchEnterprise } from '../../hooks/useSwitchEnterrpise';
import { PlusCircleIcon } from 'lucide-react';
import Spinner from '@/components/element/loading/Spinner';
import { Input } from '@/components/ui/input';

export function UserNav() {
	const { logout, user, refetchUser } = useAuth();
	const [open, setOpen] = useState(false);
	const [search, setSearch] = useState('');
	const {
		handleSwitchEnterprise,
		enterprises,
		primaryEnterpriseIndex,
		isSwitching,
	} = useSwitchEnterprise();

	if (!user) refetchUser();

	const handleLogout = (e: any) => {
		e?.preventDefault();
		logout();
	};

	return (
		<>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button
						variant="ghost"
						className="relative size-[30px] md:flex hidden rounded-full"
					>
						<Avatar className="size-[30px]">
							{user && user.avatar && (
								<AvatarImage src={user?.avatar} alt="@username" />
							)}
							{user && user?.name && (
								<AvatarFallback name={user?.name} />
							)}
						</Avatar>
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent className="w-48" align="end" forceMount>
					<DropdownMenuLabel className="font-normal">
						<div className="flex flex-col space-y-1">
							<p className="text-sm font-medium leading-none">
								{user.name}
							</p>
							<p className="text-xs leading-none text-muted-foreground">
								{user.email}
							</p>
						</div>
					</DropdownMenuLabel>
					<DropdownMenuSeparator />
					<DropdownMenuGroup>
						{navItems.map((item) => (
							<DropdownMenuItem asChild key={item.key}>
								<Link to={item.path}>
									<i className={item.icon + ' me-2'}></i>
									{item.label}
								</Link>
							</DropdownMenuItem>
						))}
					</DropdownMenuGroup>
					<DropdownMenuSeparator />
					<DropdownMenuItem asChild>
						<button className="w-full" onClick={handleLogout}>
							<i className="bi-box-arrow-left me-2"></i>
							Log out
						</button>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
			<CreateEnterpriseDialog open={open} setOpen={setOpen} />
		</>
	);
}

const navItems = [
	{
		label: 'Referral',
		key: 'referral',
		icon: 'bi-people',
		path: '/app/profile/referral',
	},
	// {
	// 	label: 'Invite Team',
	// 	key: 'invite-team',
	// 	icon: 'bi-person-plus',
	// 	path: '/app/profile/team',
	// },
	// {
	// 	label: 'API Tokens',
	// 	key: 'api-tokens',
	// 	icon: 'bi-cpu',
	// 	path: '/app/profile/api-tokens',
	// },
	// {
	// 	label: 'Exports',
	// 	key: 'exports',
	// 	icon: 'bi-cloud-download',
	// 	path: '/app/profile/exports',
	// },
];
