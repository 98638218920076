import AvatarGroupSimple from '@/components/element/avatar/AvatarGroupSimple';
import { Avatar } from '@/components/ui/avatar';
import { AvatarImage } from '@/components/ui/avatar';
import { useDaveUtility } from '@/features/dave/hooks/useDaveUtility';
import { tokenLogos } from '@/content/token-logos';
import { useMemo, useState } from 'react';
import { Button } from '@/components/ui/button';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { CaretSortIcon } from '@radix-ui/react-icons';
import { cn } from '@/lib/utils';
import { Separator } from '@/components/ui/separator';

interface ITokensGroup {
	title: string;
	type: 'coin' | 'set';
	options: {
		value: string;
		label: string;
		avatars?: string[];
		values?: string[];
		count?: number;
		logo?: string;
		isAny?: boolean;
	}[];
}

const SelectTokenSets = ({
	chainId,
	value,
	setValue,
	isAnyToken,
	setIsAnyToken,
	setShowNewToken,
	setIsInputDisabled,
}: {
	chainId: number;
	value: string[];
	setValue: (tokens: string[]) => void;
	isAnyToken: 'ANY' | 'LIST';
	setIsAnyToken: (e: 'ANY' | 'LIST') => void;
	setShowNewToken: (e: boolean) => void;
	setIsInputDisabled: (e: boolean) => void;
}) => {
	const { supportedTokens } = useDaveUtility(chainId);
	const [open, setOpen] = useState(false);
	// const [selected, setSelected] = useState([]);

	const groups: ITokensGroup[] = useMemo(() => {
		if (!supportedTokens) return;
		const tokenLogos_ = supportedTokens.map((token: any) => {
			// const logo = tokenLogos.find(
			// 	(i) => i.symbol?.toLowerCase() === token.symbol?.toLowerCase(),
			// )?.logoURI;
			return {
				symbol: token.symbol,
				logo: token.logo,
				value: token.address?.toLowerCase(),
				label: token?.symbol?.toUpperCase(),
				isWhiteListed: token.isWhitelisted,
				peggedTo: token.peggedTo,
			};
		});
		const usdTokens =
			tokenLogos_?.filter((i) => i.symbol?.toLowerCase()?.includes('usd')) ??
			[];
		return [
			{
				title: 'Coin Sets',
				type: 'set',
				options: [
					{
						label: 'All Supported Coins',
						value: 'all',
						isAny: true,
						avatars: tokenLogos_.map((i: any) => i.logo),
						count: tokenLogos_.length,
						values: tokenLogos_.map((i: any) => i.value),
					},
					{
						label: 'USDC Coins',
						value: 'stables',
						isAny: false,
						avatars: usdTokens.map((i: any) => i.logo),
						count: usdTokens.length,
						values: usdTokens.map((i: any) => i.value),
					},
				],
			},
			{ title: 'All coins', options: tokenLogos_, type: 'coin' },
		];
	}, [supportedTokens]);

	const selected = useMemo(() => {
		if (!value) return [];
		if (!groups) return [];
		if (isAnyToken === 'ANY') return ['all'];
		const allSupportedCoins = groups
			.find((i) => i.type === 'set')
			?.options?.find((i) => i.value === 'all')?.values;
		if (value.length === allSupportedCoins?.length) {
			return ['all'];
		}
		return value;
	}, [value, groups]);

	const handleSelectCoin = (option: string, group: ITokensGroup) => {
		const selectedOption = group.options.find((i) => i.value === option);
		if (selectedOption.isAny) {
			setIsAnyToken('ANY');
		} else {
			setIsAnyToken('LIST');
		}
		if (group.type === 'set') {
			if (!selectedOption.isAny) {
				setValue(group.options.find((i) => i.value === option)?.values);
			} else {
				setValue([]);
			}
			setOpen(false);
		} else {
			const newSelected = [...selected];
			const setOptions = groups
				.filter((i) => i.type === 'set')
				.map((i) => i.options)
				.flat()
				.map((i) => i.value);
			let newSelectedFiltered = newSelected.filter(
				(i) => !setOptions.includes(i),
			);

			if (newSelectedFiltered?.includes(option)) {
				newSelectedFiltered = newSelectedFiltered.filter(
					(i) => i !== option,
				);
			} else {
				newSelectedFiltered.push(option);
			}
			setValue(newSelectedFiltered);
		}
	};

	const setOptions =
		groups
			?.filter((i) => i.type === 'set')
			?.map((i) => i.options)
			?.flat() ?? [];

	useMemo(() => {
		const tokensToCheck = selected?.includes('all')
			? supportedTokens
			: supportedTokens?.filter((token) => selected?.includes(token?.address));
		const isDisabled = !tokensToCheck?.some(
			(token) =>
				token.isWhitelisted || ['ETH', 'USD'].includes(token.peggedTo),
		);
		setIsInputDisabled(isDisabled);
	}, [selected, supportedTokens, setIsInputDisabled]);

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					role="combobox"
					className={cn(`w-full flex justify-between font-normal`)}
				>
					<div className="truncate font-normal">
						{!selected || selected.length === 0
							? 'Select Tokens'
							: selected.length === 1
								? setOptions
										.map((i) => i.value)
										.includes(selected[0])
									? setOptions.find((i) => i.value === selected[0])
											?.label
									: selected[0]
								: `${selected.length} tokens selected`}
					</div>
					<CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent className={`w-[260px] p-0`} align="start">
				<Options
					groups={groups}
					placeholder={''}
					handleSelectCoin={handleSelectCoin}
					selected={selected}
				/>
				<Separator />
				<Button
					variant="ghost"
					className="w-full text-sm font-normal"
					onClick={() => setShowNewToken(true)}
				>
					<i className="bi bi-plugin me-2"></i> Add Custom Token
				</Button>
			</PopoverContent>
		</Popover>
	);
};

function Options({
	selected,
	groups,
	placeholder,
	handleSelectCoin,
}: {
	placeholder: string;
	selected: string[];
	groups: ITokensGroup[];
	handleSelectCoin: (option: string, group: ITokensGroup) => void;
}) {
	return (
		<Command>
			<CommandInput placeholder={placeholder} />
			<CommandList>
				<CommandEmpty>No results found.</CommandEmpty>
				{groups?.map((group) => (
					<CommandGroup key={group.title} heading={group.title}>
						{group.options.map((option) => (
							<CommandItem
								key={option.value}
								className="truncate"
								value={option.value}
								onSelect={(option) => {
									handleSelectCoin(option, group);
								}}
							>
								{group.type === 'coin' ? (
									<>
										<div className="flex">
											{selected?.includes(option.value) ? (
												<i className="bi-check-circle-fill me-2 text-primary" />
											) : (
												<i className="bi-circle me-2 text-muted-foreground opacity-40" />
											)}
											<Avatar className="size-5 me-2">
												<AvatarImage src={option.logo} />
											</Avatar>
										</div>
									</>
								) : (
									<AvatarGroupSimple images={option.avatars} />
								)}
								<span className="ms-2">{option.label}</span>
							</CommandItem>
						))}
					</CommandGroup>
				))}
			</CommandList>
		</Command>
	);
}

export default SelectTokenSets;
