import InputDuration from '@/components/element/inputs/input-duration/InputDuration';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { updateCampaignEndDate } from '../../services/admin.service';
import { handleErrorMessage } from '@/utils/notifications';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '@/lib/react-query';

const CampaignEndDateDialog = ({
	open,
	setOpen,
	campaign,
	setCampaign,
	refetch,
}) => {
	const [isLoading, setIsLoading] = useState(false);

	const editDetails = (
		key: string,
		value: string | Date | boolean | { x: number; y: number },
	) => {
		try {
			setCampaign((prev) => ({ ...prev, [key]: value }));
		} catch (error) {
			console.log(error);
		}
	};
	const handleCampaignUpdate = async () => {
		setIsLoading(true);
		try {
			await updateCampaignEndDate(campaign?._id, {
				endDate: campaign?.endDate,
				stopImmediately: campaign?.stopImmediately,
			});
			setOpen(false);
		} catch (error) {
			handleErrorMessage(error);
		} finally {
			setIsLoading(false);
			await queryClient.invalidateQueries({
				queryKey: ['admin', 'campaigns'],
			});
			refetch();
		}
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[570px] min-h-[10rem] overflow-visible">
				<DialogHeader className="border-b pb-3">
					<DialogTitle className="flex justify-between">
						Edit Campaign End Date
					</DialogTitle>
					<DialogDescription>
						Change the end date of the{' '}
						<strong className="max-w-[10rem] truncate">
							{campaign?.name}
						</strong>
						&nbsp;campaign
					</DialogDescription>
				</DialogHeader>
				<div className="mb-2">
					<InputDuration
						className=""
						startDate={campaign?.startDate}
						endDate={campaign?.endDate}
						setStartDate={(date) => {}}
						setEndDate={(date) => editDetails('endDate', date)}
						startImmediately={campaign?.startImmediately}
						setStartImmediately={(e) => {}}
						timezone={campaign?.endDateTz}
						setTimezone={(e) => editDetails('timezone', e)}
						noDeadline={campaign?.noEndTime}
						setNoDeadline={(e) => editDetails('noEndTime', e)}
						disableStartDate
						disableEndTime
						stopImmediately={campaign?.stopImmediately}
						setStopImmediately={(e) => editDetails('stopImmediately', e)}
					/>
				</div>
				<DialogFooter className="space-x-2 border-t pt-3 flex">
					<Button variant="ghost" onClick={() => setOpen(close)}>
						Cancel
					</Button>
					<Button
						onClick={() => handleCampaignUpdate()}
						disabled={isLoading}
					>
						{isLoading ? (
							<i className="bi-arrow-clockwise animate-spin me-2"></i>
						) : null}
						Submit
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default CampaignEndDateDialog;
