import DashboardMobileMenu from './DashboardMobileNav';
import { Button } from '@/components/ui/button';
import { UserNav } from './UserNav';
import { DashboardTabs } from './DashboardTabs';
import { Separator } from '@/components/ui/separator';
import Notifications from '../notifications/Notifications';
import { Link } from 'react-router-dom';

export default function DashboardLinks() {
	return (
		<div className="flex items-center">
			<div className="md:flex hidden items-center">
				<div className=" mb-1">
					<DashboardTabs />
				</div>
			</div>
			<div className="mx-3 me-6">
				<Separator orientation="vertical" className="h-[26px]" />
			</div>
			<div className="gap-2 flex items-center">
				<Button size="icon" variant="ghost" className="rounded-full" asChild>
					<Link to="/app/community-settings/credits">
						<div className="bi-credit-card text-lg text-muted-foreground relative"></div>
					</Link>
				</Button>
				<Notifications />
				<UserNav />
				<DashboardMobileMenu />
			</div>
		</div>
	);
}
