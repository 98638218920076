import StatusBadge from '@/components/element/badges/StatusBadge';
import { Media } from '@/components/element/media/Media';
import { Badge } from '@/components/ui/badge';
import {
	CommandDialog,
	CommandEmpty,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from '@/components/ui/command';
import { Status } from '@/features/campaigns/types';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { useGetAdminCampaigns } from '../../hooks/useGetAdminCampaigns';
import { IWidgets } from '../../types/cms.types';

export function SearchAllQuests({
	open,
	setOpen,
	onSelect,
	searchQuests,
	isLoyaltyWidget,
}: {
	setOpen: Dispatch<SetStateAction<boolean>>;
	open: boolean;
	onSelect?: any;
	searchQuests?: IWidgets['campaign'];
	isLoyaltyWidget?: boolean;
}) {
	const {
		results: campaigns,
		search,
		setSearch,
		filters,
		setFilters,
		pagination,
		setPagination,
		sorting,
		setSorting,
		isLoading,
		refetch,
		count,
	} = useGetAdminCampaigns();

	const updateFiltersAndRefetch = useCallback(() => {
		setFilters((prev) => ({
			...prev,
			campaignStatus: isLoyaltyWidget ? [] : [Status.Active],
			publishStatus: isLoyaltyWidget ? false : true,
			fetchLoyaltyCampaigns: isLoyaltyWidget,
		}));

		setPagination((prev) => ({
			...prev,
			pageSize: 30,
		}));

		refetch();
	}, [isLoyaltyWidget, setFilters, setPagination, refetch]);

	useEffect(() => {
		updateFiltersAndRefetch();
	}, [isLoyaltyWidget, updateFiltersAndRefetch]);

	const filteredCampaigns = useMemo(() => {
		if (campaigns) {
			return campaigns.filter((i) =>
				searchQuests?.every((j) => j.id !== i._id),
			);
		}
		return [];
	}, [campaigns, searchQuests]);

	return (
		<CommandDialog open={open} onOpenChange={setOpen}>
			<CommandInput
				placeholder="Search from all the active Campaigns"
				value={search}
				onValueChange={(newVal) => setSearch(newVal)}
			/>
			<CommandList className="text-xs">
				{filteredCampaigns.length === 0 && !isLoading && (
					<CommandEmpty>No results found.</CommandEmpty>
				)}
				{isLoading && <CommandEmpty>Loading...</CommandEmpty>}
				{filteredCampaigns?.map((i, idx) => (
					/**removed <CommandItem /> as it was not rendering the latest results
					 * TODO: find a fix   */
					<div
						key={i._id}
						onClick={() => {
							onSelect(i);
							setOpen(false);
						}}
						className="relative flex flex-col items-center rounded-sm px-2 py-3 text-sm outline-none border-b"
					>
						<div className="w-full">
							<div className="flex justify-between items-center w-full gap-2">
								<div className="flex items-center w-full cursor-pointer">
									<Media
										src={i.banner}
										className="h-6 w-10 rounded me-3"
									/>
									<div className=" max-w-[21rem] truncate">
										{i.name}
									</div>
								</div>
								<div className="w-[6rem]">
									<Badge variant="outline" className="text-xs">
										<StatusBadge status={i.status} />
									</Badge>
								</div>
							</div>
						</div>
					</div>
				))}
			</CommandList>
		</CommandDialog>
	);
}
