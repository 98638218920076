import {
	CampaignRewardCategory,
	CampaignRewardType,
	ICampaign,
	ICampaignAddReward,
} from '../../types';
import { useCreateCampaign } from './useCreateCampaign';
import { v4 as uuidv4 } from 'uuid';

export const useCreateCampaignRewards = () => {
	const { rewards, setRewards, setIsDirty } = useCreateCampaign();

	const initialiseRewards = (data?: ICampaign, isDuplicate?: boolean) => {
		const newRewards: ICampaignAddReward[] = Object.values(
			CampaignRewardCategory,
		).map((category) => {
			if (data) {
				if (category === CampaignRewardCategory.OfficialPoints) {
					const officialPointsReward = data.reward.find(
						(i) =>
							i.category === CampaignRewardCategory.Whitelist &&
							i.whitelistReward.enableCustomPoints,
					);
					if (officialPointsReward) {
						return {
							...officialPointsReward,
							category: CampaignRewardCategory.OfficialPoints,
							isActive: true,
							_id: isDuplicate ? uuidv4() : officialPointsReward?._id,
						};
					}
				} else if (category === CampaignRewardCategory.Whitelist) {
					const whiteListReward = data.reward.find(
						(i) =>
							i.category === CampaignRewardCategory.Whitelist &&
							!i.whitelistReward.enableCustomPoints,
					);
					if (whiteListReward) {
						return {
							...whiteListReward,
							isActive: true,
							_id: isDuplicate ? uuidv4() : whiteListReward._id,
						};
					}
				} else if (data.reward.find((i) => i.category === category)) {
					return {
						...data.reward.find((i) => i.category === category),
						isActive: true,
						_id: isDuplicate
							? uuidv4()
							: data.reward.find((i) => i.category === category)?._id,
					};
				}
			}
			let categoryObject = {};
			if (category === CampaignRewardCategory.DiscordRole) {
				categoryObject = {
					discordRoleReward: {
						roleId: '',
						roleName: '',
					},
				};
			}
			if (category === CampaignRewardCategory.Token) {
				categoryObject = {
					tokenReward: {
						tokenAddress: '',
						tokenSymbol: '',
						chainId: 137,
						tokenDecimals: 8,
						logo: '',
						name: '',
						tokenAmountPerUser: '',
					},
				};
			}
			if (category === CampaignRewardCategory.Nft) {
				categoryObject = {
					nftReward: {
						nftTokenId: '',
						nftDocId: '',
						chainId: 137,
						nftAmountPerUser: 1,
						name: '',
						image: '',
						isGasless: false,
					},
				};
			}
			if (category === CampaignRewardCategory.Whitelist) {
				categoryObject = {
					whitelist: {
						name: '',
						image: '',
						nftImage: [],
					},
				};
			}
			if (category === CampaignRewardCategory.OfficialPoints) {
				categoryObject = {
					whitelist: {
						name: '',
						image: '',
						nftImage: [],
						pointsPerUser: 1,
						enableCustomPoints: true,
					},
				};
			}

			const rewardObject: ICampaignAddReward = {
				category,
				method:
					category === CampaignRewardCategory.DiscordRole
						? CampaignRewardType.Unlimited
						: CampaignRewardType.Fcfs,
				numRewards: null,
				isActive: false,
				isDistributedOnPlatform: true,
				...categoryObject,
				isUnlimited:
					category === CampaignRewardCategory.DiscordRole ? true : false,
			};
			return rewardObject;
		});
		return newRewards;
	};

	const toggleRewardMethod = (category: CampaignRewardCategory) => {
		const newRewards = [...rewards];
		const index = newRewards.findIndex((r) => r.category === category);
		newRewards[index] = {
			...newRewards[index],
			isActive: !newRewards[index]?.isActive,
		};
		setIsDirty(true);
		setRewards(newRewards);
	};

	return {
		toggleRewardMethod,
		initialiseRewards,
	};
};
