import { Button } from '@/components/ui/button';
import InputPassword from '@/components/element/inputs/InputPassword';
import { useRouter } from '@/hooks/useRouter';
import { useEffect } from 'react';
import useAcceptInvite from '../hooks/useAcceptInvite';
import notFound from '@/assets/img/error-404-not-found.svg';
import AuthLayout2 from '@/components/layout/auth/AuthLayout2';
import logo from '@/assets/logo.svg';

interface IQuery {
	[x: string]: string | (string | null)[] | null | undefined;
}
const AcceptInvitePage = () => {
	const { query } = useRouter();
	const {
		formFields,
		setFormFields,
		formErrors,
		isLoading,
		handleSubmit,
		setFormErrors,
		hashError,
		userExists,
	} = useAcceptInvite(query);

	useEffect(() => {
		setFormErrors({});
	}, [formFields, setFormErrors]);

	return (
		<AuthLayout2>
			<div className="mx-auto h-full flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
				<img src={logo} alt="logo" className="mx-auto h-10 w-10" />
				<div className="flex flex-col space-y-2 text-center">
					<h1 className="text-2xl font-semibold tracking-tight">
						Welcome to Intract
					</h1>
					<p className="text-sm text-muted-foreground">
						Set your password to join your team and get started
					</p>
				</div>
				{hashError ? (
					<div>
						<img
							src={notFound}
							alt="Page not found"
							className="mx-auto w-1/2"
						/>
						<h1 className="text-lg font-base tracking-tight text-center mt-4 text-black/70 ">
							{hashError}
						</h1>
					</div>
				) : (
					<div className="grid gap-6">
						<form onSubmit={handleSubmit}>
							<div className="grid gap-2">
								<div className="grid gap-5">
									{!userExists && (
										<>
											<InputPassword
												className="mt-2"
												label="Your new password"
												placeholder="8+ characters required"
												required
												error={formErrors['password']}
												errorText={formErrors['password']}
												value={formFields.password}
												setValue={(e) =>
													setFormFields((prev: any) => ({
														...prev,
														password: e,
													}))
												}
											/>
											<InputPassword
												label="Confirm your new password"
												placeholder="8+ characters required"
												required
												error={formErrors['confirmPassword']}
												errorText={
													formErrors['confirmPassword']
												}
												value={formFields.confirmPassword}
												setValue={(e) =>
													setFormFields((prev: any) => ({
														...prev,
														confirmPassword: e,
													}))
												}
											/>
										</>
									)}
									<Button
										disabled={isLoading || !!hashError}
										className="mt-2"
									>
										{isLoading ? (
											<i className="bi bi-arrow-clockwise animate-spin mr-1 text-sm"></i>
										) : null}
										Join the team
									</Button>
								</div>
							</div>
						</form>
					</div>
				)}
			</div>
		</AuthLayout2>
	);
};

export default AcceptInvitePage;
