import { uploadFile } from '@/services/utility.service';
import { useRewardStore } from './useRewardStore';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '@/lib/react-query';
import { createReward, updateRewardData } from '../services/reward-store.service';
import { toast } from 'sonner';
import { EligibilityType, RewardType } from '../types/reward-store.enums';
import { useState } from 'react';
import { handleErrorMessage } from '@/utils/notifications';
import { IReward } from '../types/reward-store.type';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';

const useCreateNewRewards = ({
	steps,
	setSteps,
	resetForm,
	setOpen,
}: {
	steps?: number;
	setSteps?: any;
	resetForm: any;
	setOpen: any;
}) => {
	const {
		formFields,
		setFormFields,
		formErrors,
		setFormErrors,
		rewardStore,
		setSections,
	} = useRewardStore();

	const [loading, setLoading] = useState(false);

	const createMutation = useMutation({
		onSuccess: async () => {
			resetForm();
			setOpen(false);
			setSteps(1);
			toast.success('Reward created successfully');
			queryClient.invalidateQueries({ queryKey: ['rewardStore'] });
		},
		onError: (error) => {
			handleErrorMessage(error);
		},
		mutationFn: createReward,
	});

	const updateRewardMutation = useMutation({
		onSuccess: async () => {
			resetForm();
			setOpen(false);
			setSteps(1);
			queryClient.invalidateQueries({ queryKey: ['rewardStore'] });
			toast.success('Reward details updated successfully');
		},
		mutationFn: updateRewardData,
	});

	const updateReward = async (reward: IReward) => {
		try {
			await updateRewardMutation.mutateAsync(reward);
		} catch (error) {
			handleErrorMessage(error);
		}
	};

	const validateStep = () => {
		const errors: any = {};
		const { rewardType, rewardDetails } = formFields ?? {};

		if (steps === 1) {
			// validate reward details
			if (!formFields.name || !formFields.name.trim()) {
				errors.name = 'Reward name is required';
			}
			if (!formFields.description || !formFields.description.trim()) {
				errors.description = 'Reward description is required';
			}
			if (formFields.eligibilityParams) {
				formFields.eligibilityParams.forEach((param) => {
					if (param.eligibilityType === EligibilityType.QuestsCompletion) {
						if (!param.campaignIds || param.campaignIds.length === 0) {
							errors.eligibilityParams =
								'Campaign IDs cannot be empty for Quests Completion eligibility.';
						}
					} else {
						if (param.minCount == 0) {
							errors.eligibilityParams =
								'Criteria completed cannot be 0.';
						}
					}
				});
			}

			// Validate based on the type of reward
			switch (rewardType) {
				case RewardType.Nft:
					if (
						!rewardDetails?.nftDetails ||
						!rewardDetails.nftDetails.nftDocId ||
						!rewardDetails.nftDetails.nftContractAddress ||
						!rewardDetails.nftDetails.nftTokenId ||
						!rewardDetails.nftDetails.nftChainId
					) {
						errors.rewardDetails = 'Please select an NFT';
					}
					break;

				case RewardType.DiscordRole:
					if (
						!rewardDetails?.discordRoleDetails ||
						!rewardDetails.discordRoleDetails.discordRoleId ||
						!rewardDetails.discordRoleDetails.discordGuildId
					) {
						errors.rewardDetails = 'Please select a Discord Role';
					}
					break;

				case RewardType.Custom:
					if (
						!rewardDetails?.customDetails ||
						!rewardDetails.customDetails.secretCode
					) {
						errors.rewardDetails = 'Please add a secret code';
					}
					break;

				default:
					// Handle other cases or do nothing
					break;
			}
		}
		if (steps === 2) {
			if (rewardType !== RewardType.Nft && !formFields.image) {
				errors.image = 'Please upload an image';
				toast.error('Please upload an image');
			}
		}

		setFormErrors(errors);

		return Object.keys(errors).length === 0;
	};
	const handleNext = () => {
		if (!validateStep()) return;

		setLoading(true);
		if (steps === 1) {
			//upload reward image if there.

			if (formFields.editMode) {
				updateReward(formFields);
				setLoading(false);
				return;
			}
			setLoading(false);
			setSteps((prev) => prev + 1);
		} else if (steps === 2) {
			if (formFields.imageFile && formFields.image.includes('blob:')) {
				uploadFile(formFields.imageFile).then((res) => {
					// console.log(res, '==upload img res');
					setFormFields({
						...formFields,
						image: res,
					});
					if (!formFields._id) {
						createMutation.mutateAsync({
							...formFields,
							image: res,
						});
					}
					setLoading(false);
				});
			} else {
				if (!formFields._id) {
					createMutation.mutateAsync({
						...formFields,
					});
				}
				setLoading(false);
			}
		}
	};

	return {
		handleNext,
		loading,
		updateReward,
	};
};

export default useCreateNewRewards;
