import { Dispatch, SetStateAction, createContext, useState } from 'react';
import { IAPIFormFields } from '../types/task-apis.type';
import { ApiDataEnums, CreateTaskAPISteps } from '../types/task-apis.enum';

const defaultValue: IAPIFormFields = {
	apiName: '',
	apiMethod: 'POST',
	apiEndpoint: '',
	apiPayloadMethod: 'BODY',
	apiVerificationAction: 'evm',
	apiPayload: [
		{
			key: 'address',
			value: ApiDataEnums.WalletAddress,
			testValue: '',
		},
	],
	apiHeaders: [
		{
			key: 'Authorization',
			value: '',
		},
	],
	isHeaderRequired: false,
	isRecurringTasksEnabled: false,
	isMetricBasedTaskEnabled: false,
	isApiTestDone: false,
	isApiSchemaValid: false,
	responseSchema: {},
	_id: '',
};

interface ConnectAPIState {
	formFields: IAPIFormFields;
	setFormFields: Dispatch<SetStateAction<IAPIFormFields>>;
	formErrors: any;
	setFormErrors: any;
	resetForm: any;
	isLoading: boolean;
	setIsLoading: any;
	step: CreateTaskAPISteps;
	setStep: Dispatch<SetStateAction<CreateTaskAPISteps>>;
}

const initialState: ConnectAPIState = {
	formFields: defaultValue,
	setFormFields: () => {},
	formErrors: {},
	setFormErrors: () => {},
	resetForm: () => {},
	isLoading: false,
	setIsLoading: () => {},
	step: CreateTaskAPISteps.Basic,
	setStep: () => {},
};

export const ConnectAPIContext = createContext<ConnectAPIState>(initialState);

export function ConnectAPIProvider({ children }) {
	const [formFields, setFormFields] = useState<IAPIFormFields>(defaultValue);
	const [formErrors, setFormErrors] = useState<any>({});
	const [isLoading, setIsLoading] = useState(false);
	const [step, setStep] = useState<CreateTaskAPISteps>(CreateTaskAPISteps.Basic);

	const resetForm = () => {
		setFormFields(defaultValue);
		setFormErrors({});
		setStep(CreateTaskAPISteps.Basic);
	};

	return (
		<ConnectAPIContext.Provider
			value={{
				formFields,
				setFormFields,
				formErrors,
				setFormErrors,
				resetForm,
				isLoading,
				setIsLoading,
				step,
				setStep,
			}}
		>
			{children}
		</ConnectAPIContext.Provider>
	);
}
