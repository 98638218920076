import { createContext, useEffect, useState } from 'react';
import { ICampaign, ITask, ITaskSection, ReportTabs, Status } from '../types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getCampaign, reorderTasks } from '../services/campaigns.service';
import { useNavigate, useParams } from 'react-router-dom';
import { QUEST_URL } from '@/config';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { handleErrorMessage } from '@/utils/notifications';
import { queryClient } from '@/lib/react-query';
import { TaskSectionUnitTypes } from '../types/tasks.enums';

interface CampaignProviderState {
	campaign: ICampaign;
	isLoading: boolean;
	id: string;
	link: string;
	selectedTab: ReportTabs;
	setSelectedTab: (tab: ReportTabs) => void;
	hasPowTasks: boolean;
	campaignTasks: ITask[];
	handleReorder: (newOrder: { id: string }[]) => void;
}

export const CampaignContext = createContext<CampaignProviderState>({
	campaign: null,
	isLoading: false,
	id: '',
	link: '',
	selectedTab: ReportTabs.Analytics,
	setSelectedTab: () => {},
	hasPowTasks: false,
	campaignTasks: [],
	handleReorder: () => {},
});

export function CampaignProvider({ children }) {
	const navigate = useNavigate();
	const [selectedTab, setSelectedTab] = useState(ReportTabs.Overview);
	const [recentCampaigns, setRecentCampaigns] = useLocalStorage(
		'recent-campaigns',
		'',
	);
	const [campaignTasks, setCampaignTasks] = useState<ITask[]>([]);
	const [taskSections, setTaskSections] = useState<ITaskSection[]>(null);

	const { id } = useParams();
	const { data, isLoading, error } = useQuery<ICampaign>({
		queryKey: ['campaign', id],
		queryFn: () => getCampaign(id),
	});

	const reorderMutation = useMutation({
		mutationFn: reorderTasks,
		onSuccess: () => {
			toast.success('Tasks reordered successfully');
			queryClient.invalidateQueries({
				queryKey: ['campaign', id],
			});
		},
		onError: () => {
			toast.error('Error reordering tasks');
		},
	});

	const handleReorder = (newOrder: { id: string }[]) => {
		const newTasks = newOrder.map((task) =>
			campaignTasks.find((t) => t._id === task.id),
		);

		const updatedTaskSections = taskSections.map((section, index) => {
			if (index === 0) {
				return {
					...section,
					displayData: newTasks.map((task) => ({
						unitType: TaskSectionUnitTypes.Task,
						unitId: task._id,
					})),
				};
			}
			return section;
		});
		setCampaignTasks(newTasks);
		setTaskSections(updatedTaskSections);

		reorderMutation.mutateAsync({
			id,
			taskSections: updatedTaskSections,
		});
	};

	useEffect(() => {
		if (error) {
			handleErrorMessage(
				'Error fetching campaign report, you might be on a wrong page.',
			);
			navigate(`/app/campaign/quests`);
		}
	}, [error]);

	useEffect(() => {
		if (data && data.status === Status.Draft) {
			navigate(`/app/campaign/quests/${id}/update`);
		}
	}, [data]);

	useEffect(() => {
		if (!data) return;

		const recentCampaignsArray = recentCampaigns.split(',');
		if (!recentCampaignsArray.includes(id)) {
			recentCampaignsArray.push(id);
			setRecentCampaigns(recentCampaignsArray.join(','));
		}

		//rearrange based on order of data?.taskSections[0]?.displayData
		if (
			Array.isArray(data?.quest?.tasks) &&
			Array.isArray(data?.taskSections[0]?.displayData)
		) {
			const orderedTasks = data.taskSections[0].displayData
				.map((task_) => {
					const task = data.quest.tasks.find(
						(task) => task._id === task_.unitId,
					);
					return task || null;
				})
				.filter((task) => task !== null);

			setCampaignTasks(orderedTasks);

			setTaskSections(data.taskSections);
		}
	}, [data]);

	return (
		<CampaignContext.Provider
			value={{
				campaign: data,
				isLoading,
				id,
				selectedTab,
				setSelectedTab,
				link: `${QUEST_URL}/quest/${id}?utm_source=dashboard`,
				hasPowTasks: data?.quest?.tasks.some(
					(task: ITask) => task?.powVerifyConfig?.isPOWVerify,
				),
				campaignTasks,
				handleReorder,
			}}
		>
			{children}
		</CampaignContext.Provider>
	);
}
