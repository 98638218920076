import InputText from '@/components/element/inputs/InputText';
import { Button } from '@/components/ui/button';
import { Dispatch, SetStateAction } from 'react';
import { IDaveContracts } from '../../types/dave.types';
import { SelectNetwork2 } from '@/components/element/inputs/SelectNetworks2';
import InputWrapper from '@/components/element/inputs/InputWrapper';
import { useDaveUtility } from '../../hooks/useDaveUtility';
import { links } from '@/config/links';

const ContractDetails = ({
	details,
	setDetails,
	errors,
	chainId,
}: {
	handleNext: () => void;
	details: IDaveContracts;
	setDetails: Dispatch<SetStateAction<IDaveContracts>>;
	errors: {
		contractAddress: string;
		existingContract: boolean;
	};
	chainId: number;
}) => {
	const { supportedChains, isChainsLoading } = useDaveUtility();
	return (
		<>
			<div className="mb-0 flex justify-between border-b items-center pb-3">
				<div className="text-lg font-medium">
					{details?._id ? 'Update Your Contract' : 'Add Your Contract'}
				</div>
				<Button
					variant="link"
					onClick={() =>
						window.open(links.docs.needHelpAddingContract, '_blank')
					}
				>
					<i className="bi bi-question-circle-fill  me-1"></i>
					Need Help
				</Button>
			</div>
			<p className="text-xs mb-5">
				You can import unverified contracts or contracts that have been
				verified publicly on popular block explorers.
			</p>
			<InputText
				label="Contract Address"
				placeholder="0x0872ec44...Efcec61b3c9"
				value={details.contractAddress}
				setValue={(e) => setDetails((p) => ({ ...p, contractAddress: e }))}
				error={!!errors.contractAddress}
				errorText={
					errors.existingContract
						? 'This contract is already added. Please select from the dropdown'
						: 'Invalid contract address'
				}
			/>
			<InputWrapper label="Network" className="mt-2">
				{isChainsLoading ? (
					<>Loading...</>
				) : (
					<SelectNetwork2
						value={details.chainId}
						allowedChainIds={supportedChains?.map(
							(chain) => chain.chainId,
						)}
						placeholder="Select Network"
						setValue={(e) => {
							setDetails((p) => ({
								...p,
								chainId: e.chainId as number,
							}));
						}}
						disabled={!!chainId}
					/>
				)}
			</InputWrapper>

			<InputText
				label="Contract Name (Optional)"
				placeholder="Contract Name"
				value={details.name}
				setValue={(e) => setDetails((p) => ({ ...p, name: e }))}
				error={false}
				className="mt-3"
			/>
		</>
	);
};

export default ContractDetails;
