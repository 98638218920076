import { Navigate, Route, Routes } from 'react-router-dom';
import CommunitySettingsLayout from '../layout/CommunitySettingsLayout';
import IntractCreditsPage from '@/features/profile/routes/credits.page';
import EnterpriseTeamPage from '@/features/profile/routes/team.page';
import IntegrationsPage from '@/features/integrations/routes/integrations.page';
import CustomiseHubPage from '@/features/customise-hub/routes/customise-hub.page';
import ProjectSocials from '@/features/customise-hub/routes/customise-socials.page';
import { CommunityHubProvider } from '@/features/customise-hub/providers/CommunityHubProvider';
import CommunityReferral from '@/features/customise-hub/routes/community-referral.page';
import ExportsPage from '@/features/profile/routes/exports.page';

export default function CommunitySettingRoutes() {
	return (
		<CommunitySettingsLayout>
			<Routes>
				<Route
					path="profile"
					element={
						<CommunityHubProvider>
							<CustomiseHubPage />
						</CommunityHubProvider>
					}
				/>
				<Route
					path="socials"
					element={
						<CommunityHubProvider>
							<ProjectSocials />
						</CommunityHubProvider>
					}
				/>
				<Route path="team" element={<EnterpriseTeamPage />} />
				<Route path="credits" element={<IntractCreditsPage />} />
				<Route path="integrations" element={<IntegrationsPage />} />
				<Route path="exports" element={<ExportsPage />} />
				<Route path="community-referral" element={<CommunityReferral />} />
				<Route path="*" element={<Navigate replace to="/" />} />
			</Routes>
		</CommunitySettingsLayout>
	);
}
