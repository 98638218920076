import InputDuration from '@/components/element/inputs/input-duration/InputDuration';
import InputText from '@/components/element/inputs/InputText';
import InputTextArea from '@/components/element/inputs/InputTextArea';
import { validateCompleteURL } from '@/utils/parsers';

const AddBannerDetails = ({ formFields, setFormFields, formErrors }) => {
	return (
		<div className="space-y-5">
			<InputText
				label="Enterprise Id"
				placeholder="Enter enterprise id"
				value={formFields.enterpriseId}
				setValue={(e) => setFormFields({ ...formFields, enterpriseId: e })}
				error={formErrors['enterpriseId']}
				errorText={formErrors['enterpriseId']}
				required
			/>
			<InputText
				label="Banner Title"
				placeholder="Enter banner title"
				value={formFields.title}
				setValue={(e) => setFormFields({ ...formFields, title: e })}
				error={formErrors['title']}
				errorText={formErrors['title']}
				required
			/>

			<InputText
				label="Banner Link"
				placeholder="Enter banner link"
				value={formFields.link}
				setValue={(e) => setFormFields({ ...formFields, link: e })}
				error={formErrors['link']}
				errorText={formErrors['link']}
				required
				onBlur={() => {
					if (formFields.link) {
						setFormFields({
							...formFields,
							link: validateCompleteURL(formFields.link),
						});
					}
				}}
			/>
			<InputTextArea
				label="Banner Description"
				placeholder="Enter banner description"
				value={formFields.description}
				setValue={(e) => setFormFields({ ...formFields, description: e })}
				error={formErrors['description']}
				errorText={formErrors['description']}
				required
			/>
		</div>
	);
};

export default AddBannerDetails;
