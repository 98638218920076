import { deepCopy } from '@/utils/parsers';
import {
	AdminInputType,
	ICampaign,
	ICreateTasks,
	ITaskTemplates,
	TaskKeys,
} from '../../types';
import { useCreateCampaign } from './useCreateCampaign';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'sonner';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { MetricBasedXpFunction } from '../../types/tasks.enums';

export interface IEditingTasks {
	id: string;
	type: 'task' | 'group';
	task?: ICreateTasks;
	title?: string;
	tasks?: ICreateTasks[];
	completion?: {
		method: string;
		minXP: number;
		minTasks: number;
		canContinue: boolean;
	};
	recurrance: {
		frequency: number;
	};
}

export const useCreateCampaignTasks = () => {
	const { tasks, setTasks, errors, setErrors, setIsDirty } = useCreateCampaign();

	const reorderTasks = (result: any) => {
		setIsDirty(true);
		const items = Array.from(tasks);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		analytics.track(TrackingEvents.CreateQuestReorderTasks, {});
		setTasks(items);
	};

	const addTaskGroup = () => {
		setTasks((prev: any) => [
			...prev,
			{
				id: Math.random()?.toString(),
				type: 'group',
				title: '',
				tasks: [],
				completion: {
					method: 'all',
					minXP: 0,
					minTasks: 0,
					canContinue: true,
				},
				recurrance: {
					frequency: 0,
				},
			},
		]);
	};

	const makeTaskActive = (taskId: string) => {
		setTasks((prev) => {
			const newTasks = [...prev];
			for (const task of newTasks) {
				if (task.type === 'group') {
					for (const subTask of task.tasks) {
						if (subTask.taskId === taskId) {
							subTask.isEditing = true;
						} else {
							subTask.isEditing = false;
						}
					}
				} else {
					if (task.task.taskId === taskId) task.task.isEditing = true;
					else task.task.isEditing = false;
				}
			}
			return newTasks;
		});
	};

	const addTask = (template: ITaskTemplates, icon: string, sectionId: string) => {
		setIsDirty(true);
		analytics.track(TrackingEvents.CreateQuestAddTask, {
			templateType: template?.templateType,
			templateFamily: template?.templateFamily,
		});
		const task: ICreateTasks = {
			name: template.name,
			description: template.description,
			adminHelper: template.adminHelper,
			xp: template.xp,
			adminInputs: template.adminInputs,
			logo: template.logo
				? template.logo
				: { value: `bi-${icon}`, type: 'icon' },
			featureApplicability: template.featureApplicability,
			appliedFeatures: {
				recurrance: {
					enabled: false,
				},
				maxFailedCount: {
					enabled: false,
				},
				powVerification: {
					enabled: false,
				},
				metricBasedXp: {
					enabled: false,
				},
			},
			maxFailedCountConfig: {
				isMaxFailedCountEnabled: false,
				maxFailedCount: 0,
				isDiminishingXpEnabled: false,
			},
			recurrenceConfig: {
				isRecurring: false,
				frequencyInDays: 0,
			},
			metricBasedXpConfig: {
				functionType: MetricBasedXpFunction.None,
				// linearMetadata: {
				// 	xpMultiplier: 1,
				// },
			},
			customInitiationURL: '',
			templateFamily: template.templateFamily,
			templateType: template.templateType,
			isRequiredTask: true,
			isEditing: true,
			taskId: uuidv4(),
			isCustomInitiationURLAllowed: template.isCustomInitiationURLAllowed,
			integrationRequired: template.integrationRequired,
			isAdminRequiredLoginsSelectionNeeded:
				template.isAdminRequiredLoginsSelectionNeeded,
			requiredLogins:
				template.requiredLogins?.length > 0
					? [template.requiredLogins?.[0]]
					: [],
			requiredLoginOptions: template.requiredLogins,
			minXp: template.minXp,
			maxXp: template.maxXp,
			learningContent: {
				enabled: false,
				slides: [],
			},
			powVerifyConfig: {
				isPOWVerify: template.featureApplicability?.powVerification,
			},
		};
		if (sectionId) {
			const newTasks = [...tasks];
			const index = newTasks.findIndex(
				(i: any) => i.id === sectionId && i.type === 'group',
			);
			newTasks[index].tasks.push(task);
			setTasks(newTasks);
		} else {
			setTasks((prev: IEditingTasks[]) => {
				return [
					...prev.map((k: any) => {
						if (k.type === 'task') k.task.isEditing = false;
						else {
							k.tasks = k.tasks.map((l: any) => {
								l.isEditing = false;
								return l;
							});
						}
						return k;
					}),
					{
						id: Math.random()?.toString(),
						type: 'task',
						task: task,
						isEditing: true,
					},
				];
			});
		}
	};

	const moveTask = (taskId: string, direction: 'up' | 'down') => {
		setIsDirty(true);
		const items = Array.from(tasks);
		const section = items.filter((i) => {
			if (i.type === 'group') {
				return i.tasks.filter((j) => j.taskId === taskId).length > 0;
			} else {
				return i.task.taskId === taskId;
			}
		})?.[0];
		if (!section) return;
		if (section.type === 'group') {
			return;
		}
		if (section.type === 'task') {
			const index = items.findIndex((i) => i.id === section.id);
			const [reorderedItem] = items.splice(index, 1);
			items.splice(index + (direction === 'down' ? 1 : -1), 0, reorderedItem);
		}
		setTasks(items);
	};

	const deleteTask = (taskId: string) => {
		setIsDirty(true);
		const task = tasks.filter((i) => {
			if (i.type === 'group') {
				return i.tasks.filter((j) => j.taskId === taskId).length > 0;
			} else {
				return i.task.taskId === taskId;
			}
		})?.[0]?.task;
		const newTasks = tasks
			.map((i) => {
				if (i.type === 'group') {
					if (i.tasks.filter((j) => j.taskId === taskId).length === 0) {
						return i;
					}
					return {
						...i,
						tasks: i.tasks.filter((j) => j.taskId !== taskId),
					};
				} else {
					if (i.task.taskId !== taskId) return i;
					else return null;
				}
			})
			.filter((i) => i);
		if (newTasks.length === 1 && !newTasks?.[0].task?.isRequiredTask) {
			toast.error(
				'Deleting this task will leave the campaign without any required tasks. Please add another task before deleting this one.',
			);
			return;
		}
		analytics.track(TrackingEvents.CreateQuestDeleteTask, {
			templateType: task?.templateType,
			templateFamily: task?.templateFamily,
		});
		setTasks(newTasks);
	};

	const editTaskGroupCompletion = (
		sectionId: string,
		key: string,
		value: string | number | boolean | string[],
	) => {
		const newSection = tasks.map((i) => {
			if (i.id === sectionId) {
				return {
					...i,
					completion: {
						...i.completion,
						[key]: value,
					},
				};
			}
			return i;
		});
		setTasks(newSection);
	};

	const editTaskGroupRecurrance = (sectionId: string, frequency: number) => {
		const newSection = tasks.map((i) => {
			if (i.id === sectionId) {
				return {
					...i,
					recurrance: {
						...i.recurrance,
						frequency,
					},
				};
			}
			return i;
		});
		setTasks(newSection);
	};

	const editTask = (
		taskId: string,
		action:
			| 'EDIT_NAME'
			| 'LEARNING_CONTENT_TOGGLE'
			| 'EDIT_XP'
			| 'OPTIONAL'
			| 'MAX_RETRIES_TOGGLE'
			| 'CUSTOM_INITIATION_URL'
			| 'MAX_RETRIES'
			| 'EDIT_RECURRANCE'
			| 'EDIT_SLIDES'
			| 'RECURRING_TOGGLE'
			| 'REQUIRED_LOGIN'
			| 'METRIC_BASED_XP_TOGGLE'
			| 'EDIT_METRIC_BASED_XP'
			| 'EDIT_TASK_LOGO',
		value: any,
	) => {
		setIsDirty(true);
		setTasks((prevTasks) => {
			const newTasks = structuredClone(prevTasks);

			const taskItem = newTasks.find((item) =>
				item.type === 'group'
					? item.tasks.some((j) => j.taskId === taskId)
					: item.task.taskId === taskId,
			);

			if (!taskItem) return prevTasks;

			const task =
				taskItem.type === 'group'
					? taskItem.tasks.find((j) => j.taskId === taskId)
					: taskItem.task;

			if (!task) return newTasks;

			switch (action) {
				case 'EDIT_NAME':
					task.description = value;
					break;
				case 'REQUIRED_LOGIN':
					task.requiredLogins = [value];
					break;
				case 'CUSTOM_INITIATION_URL':
					task.customInitiationURL = value;
					break;
				case 'LEARNING_CONTENT_TOGGLE':
					task.learningContent.enabled = value;
					break;
				case 'OPTIONAL':
					if (newTasks.length === 1) {
						toast.error(
							'A single task cannot be optional. Please add another task before making this optional.',
						);
						return prevTasks;
					}
					task.isRequiredTask = value;
					break;
				case 'EDIT_XP':
					task.xp = value;
					break;
				case 'MAX_RETRIES_TOGGLE':
					if (
						(task.appliedFeatures.recurrance.enabled ||
							task.appliedFeatures.metricBasedXp.enabled) &&
						value
					) {
						task.appliedFeatures.recurrance.enabled = false;
						task.appliedFeatures.metricBasedXp.enabled = false;
					}
					task.appliedFeatures.maxFailedCount.enabled = value;
					break;
				case 'RECURRING_TOGGLE':
					if (task.appliedFeatures.maxFailedCount.enabled && value) {
						task.appliedFeatures.maxFailedCount.enabled = false;
					}
					task.appliedFeatures.metricBasedXp.enabled = false;
					task.appliedFeatures.recurrance.enabled = value;

					task.metricBasedXpConfig = {
						functionType: MetricBasedXpFunction.None,
					};
					break;
				case 'METRIC_BASED_XP_TOGGLE':
					if (task.appliedFeatures.maxFailedCount.enabled && value) {
						task.appliedFeatures.maxFailedCount.enabled = false;
					}
					task.appliedFeatures.recurrance.enabled = false;
					task.appliedFeatures.metricBasedXp.enabled = value;
					task.recurrenceConfig = {
						isRecurring: false,
						frequencyInDays: 0,
					};
					break;
				case 'MAX_RETRIES':
					if (value === 0) {
						task.maxFailedCountConfig = {
							isMaxFailedCountEnabled: false,
							maxFailedCount: 0,
							isDiminishingXpEnabled: false,
						};
					} else {
						task.maxFailedCountConfig = {
							isMaxFailedCountEnabled: true,
							maxFailedCount: value,
							isDiminishingXpEnabled: true,
						};
					}
					break;
				case 'EDIT_RECURRANCE':
					task.recurrenceConfig =
						value === 0
							? {
									isRecurring: false,
									frequencyInDays: 0,
								}
							: {
									isRecurring: true,
									frequencyInDays: value,
								};
					break;
				case 'EDIT_METRIC_BASED_XP':
					handleMetricBasedXpUpdate(task, value);
					break;
				case 'EDIT_SLIDES':
					task.learningContent.slides = value;
					break;
				case 'EDIT_TASK_LOGO':
					task.logo = value;
					break;
				default:
					console.error(`Unhandled action type: ${action}`);
					return newTasks;
			}

			return newTasks;
		});
	};

	const handleMetricBasedXpUpdate = (
		task: ICreateTasks,
		value: MetricBasedXpFunction,
	) => {
		switch (value) {
			case MetricBasedXpFunction.Linear:
				task.metricBasedXpConfig = {
					functionType: value,
					linearMetadata: { xpMultiplier: 1 },
				};
				break;
			case MetricBasedXpFunction.Tiered:
				task.metricBasedXpConfig = {
					functionType: value,
					tieredMetadata: {
						tiers: [{ minMetric: 0, maxMetric: 10, tierXp: 15 }],
					},
				};
				break;
			case MetricBasedXpFunction.Sigmoid:
				task.metricBasedXpConfig = {
					functionType: value,
					sigmoidMetadata: { optimalMetricValue: 10, rateOfIncrease: 0.5 },
				};
				break;
			default:
				task.metricBasedXpConfig = {
					functionType: MetricBasedXpFunction.None,
				};
		}
	};

	const editTaskAdminInput = (
		taskId: string,
		adminInputKey: TaskKeys | 'disabled' | 'recurrance' | 'metricBasedXp',
		value: any,
	) => {
		setIsDirty(true);
		setTasks((prevTasks) => {
			const taskIndex = prevTasks.findIndex((taskItem) =>
				taskItem.type === 'group'
					? taskItem.tasks.some((task) => task.taskId === taskId)
					: taskItem.task.taskId === taskId,
			);

			if (taskIndex === -1) return prevTasks;

			const newTasks = prevTasks.map((taskItem, index) => {
				if (index !== taskIndex) return taskItem;

				const updatedTaskItem = { ...taskItem };

				if (updatedTaskItem.type === 'group') {
					const taskToUpdateIndex = updatedTaskItem.tasks.findIndex(
						(task) => task.taskId === taskId,
					);
					const updatedTask = {
						...updatedTaskItem.tasks[taskToUpdateIndex],
					};
					updateTask(updatedTask, adminInputKey, value);
					updatedTaskItem.tasks[taskToUpdateIndex] = updatedTask;
				} else if (updatedTaskItem.task.taskId === taskId) {
					const updatedTask = { ...updatedTaskItem.task };
					updateTask(updatedTask, adminInputKey, value);
					updatedTaskItem.task = updatedTask;
				}

				return updatedTaskItem;
			});

			return newTasks;
		});

		setErrors((prev: any) => ({
			...prev,
			tasks: { ...defaultTaskErrors },
			sections: {
				...prev.sections,
				TASKS: false,
			},
		}));
	};

	const updateTask = (task, adminInputKey, value) => {
		switch (adminInputKey) {
			case 'disabled':
				task.isDisabled = true;
				break;
			case 'recurrance':
				task.featureApplicability.recurrence = value;
				task.featureApplicability.metricBasedXp = false;
				break;
			case 'metricBasedXp':
				task.featureApplicability.metricBasedXp = value;
				task.featureApplicability.recurrence = false;
				break;
			default: {
				const adminInput = task.adminInputs.find(
					(i) => i.key === adminInputKey,
				);
				if (adminInput) {
					adminInput.value = value;
				}
				break;
			}
		}
	};

	const initialiseTasks = (
		data?: ICampaign,
		templates?: ITaskTemplates[],
		isNew?: boolean,
	) => {
		if (!data) return [];
		const tasks_ = [];
		const taskids =
			data.taskSections?.flatMap((i) => i.displayData)?.map((i) => i.unitId) ||
			[];
		for (const taskId of taskids) {
			const task = data.quest?.tasks.find((i) => i._id === taskId);

			// TODO: the task is coming out to be undefined,
			// so this is most likely a backend issue. But for now fixing it on the frontend side

			// For now skipping this task, on moving to the next task (Hacky soln)
			if (!task) continue;

			const template = templates.find(
				(i) => i.templateType === task.templateType,
			);
			const task_: ICreateTasks = {
				isEditing: true,
				taskId: isNew ? uuidv4() : task._id,
				name: task.name,
				description: task.description,
				adminHelper: template.adminHelper,
				logo: template.logo,
				xp: task.xp,
				templateFamily: template.templateFamily,
				templateType: template.templateType,
				adminInputs: template.adminInputs.map((adminInput_) => {
					const i = deepCopy(adminInput_);
					const adminInput = task.adminInputs.find((j) => j.key === i.key);
					if (adminInput) {
						i.value = adminInput.value;
						if (
							adminInput?.inputType === AdminInputType.InputStringArray
						) {
							if (Array.isArray(i.value)) {
								i.value = i.value.map((val) => ({
									value: val,
									id: val,
								}));
							} else {
								const values = i.value.split(',');
								i.value = values.map((val) => ({
									value: val,
									id: val,
								}));
							}
						}
						if (
							adminInput?.inputType ===
							AdminInputType.InputMultiSelectAsync
						) {
							if (Array.isArray(i.value)) {
								i.value = i.value.map((val) => ({
									value: val,
									id: val,
								}));
							} else {
								const values = i.value.split(',');
								i.value = values.map((val) => val.trim());
							}
						}
					}
					return i;
				}),
				featureApplicability: {
					...template.featureApplicability,
				},
				appliedFeatures: {
					recurrance: {
						enabled: task?.recurrenceConfig?.isRecurring,
					},
					maxFailedCount: {
						enabled: task?.maxFailedCountConfig?.isMaxFailedCountEnabled,
					},
					powVerification: {
						enabled: false,
					},
					metricBasedXp: {
						enabled:
							task?.metricBasedXpConfig?.functionType !==
							MetricBasedXpFunction.None,
					},
				},
				maxFailedCountConfig: {
					isMaxFailedCountEnabled:
						task?.maxFailedCountConfig?.isMaxFailedCountEnabled,
					maxFailedCount: task?.maxFailedCountConfig?.maxFailedCount,
					isDiminishingXpEnabled:
						task?.maxFailedCountConfig?.isDiminishingXpEnabled,
				},
				recurrenceConfig: {
					isRecurring: task?.recurrenceConfig?.isRecurring,
					frequencyInDays: task?.recurrenceConfig?.frequencyInDays,
				},
				metricBasedXpConfig: {
					...task?.metricBasedXpConfig,
				},
				learningContent: {
					enabled: task?.slides?.length > 0,
					slides: task?.slides || [],
				},
				powVerifyConfig: {
					isPOWVerify: template.featureApplicability?.powVerification,
				},
				customInitiationURL: task.customInitiationURL,
				isCustomInitiationURLAllowed: template.isCustomInitiationURLAllowed,
				isRequiredTask: task.isRequiredTask,
				integrationRequired: template.integrationRequired,
				isAdminRequiredLoginsSelectionNeeded:
					template.isAdminRequiredLoginsSelectionNeeded,
				requiredLogins:
					task.requiredLogins?.length > 0
						? [task.requiredLogins?.[0]]
						: template.requiredLogins?.length > 0
							? [template.requiredLogins?.[0]]
							: [],
				requiredLoginOptions: template.requiredLogins,
				minXp: template.minXp,
				maxXp: template.maxXp,
			};
			tasks_.push(task_);
		}

		return tasks_.map((i) => ({
			id: Math.random()?.toString(),
			type: 'task',
			task: i,
			isEditing: true,
		}));
	};

	const handleTaskUpdate = (
		taskId: string,
		newValue: number,
		property:
			| 'xp'
			| 'xpMultiplier'
			| 'tierMinMetric'
			| 'tierMaxMetric'
			| 'tierXp'
			| 'addMetricTiers'
			| 'removeMetricTiers'
			| 'optimalMetricValue'
			| 'rateOfIncrease',
		idx?: number,
	) => {
		if (typeof newValue !== 'number' && !/^\d*\.?\d*$/.test(newValue)) {
			console.log('Invalid input: not a digit');
			return; // Exit the function if input is not a valid number
		}
		setTasks((prev: IEditingTasks[]) => {
			return structuredClone(prev).map((editingTask) => {
				if (editingTask.task && editingTask.task.taskId === taskId) {
					if (property === 'xp') {
						editingTask.task.xp = newValue;
					} else if (property === 'xpMultiplier') {
						editingTask.task.metricBasedXpConfig.linearMetadata.xpMultiplier =
							newValue;
					} else if (property === 'tierMinMetric') {
						editingTask.task.metricBasedXpConfig.tieredMetadata.tiers[
							idx
						].minMetric = newValue;
					} else if (property === 'tierMaxMetric') {
						editingTask.task.metricBasedXpConfig.tieredMetadata.tiers[
							idx
						].maxMetric = newValue;
					} else if (property === 'tierXp') {
						editingTask.task.metricBasedXpConfig.tieredMetadata.tiers[
							idx
						].tierXp = newValue;
					} else if (property === 'addMetricTiers') {
						if (
							!editingTask.task.metricBasedXpConfig.tieredMetadata
								.tiers
						) {
							editingTask.task.metricBasedXpConfig.tieredMetadata.tiers =
								[];
						}
						editingTask.task.metricBasedXpConfig.tieredMetadata.tiers.push(
							{
								minMetric: newValue,
								maxMetric: newValue,
								tierXp: newValue,
							},
						);
					} else if (property === 'removeMetricTiers') {
						editingTask.task.metricBasedXpConfig.tieredMetadata.tiers.splice(
							newValue,
							1,
						);
					} else if (property === 'optimalMetricValue') {
						editingTask.task.metricBasedXpConfig.sigmoidMetadata.optimalMetricValue =
							newValue;
					} else if (property === 'rateOfIncrease') {
						editingTask.task.metricBasedXpConfig.sigmoidMetadata.rateOfIncrease =
							newValue;
					}
				}
				return editingTask;
			});
		});
	};

	return {
		tasks,
		setTasks,
		moveTask,
		editTaskAdminInput,
		reorderTasks,
		editTaskGroupRecurrance,
		deleteTask,
		addTask,
		addTaskGroup,
		editTaskGroupCompletion,
		makeTaskActive,
		editTask,
		errors: errors?.tasks,
		initialiseTasks,
		handleTaskUpdate,
	};
};

const defaultTaskErrors = {
	isEmpty: false,
	// [task._id]: {
	// adminInputs: {},
	// description: false,
	// customInitiationURL: false,
	// 	[adminInput.key]: false,
	// },
};
