import {
	CommandDialog,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from '@/components/ui/command';
import { useGlobalSearch } from '../../../../hooks/useGlobalSearch';
import { useNavigate } from 'react-router-dom';
import { Media } from '@/components/element/media/Media';
import { Badge } from '@/components/ui/badge';
import { Command } from 'cmdk';
import StatusBadge from '@/components/element/badges/StatusBadge';
import { GlobalSearchResult } from '@/providers/SearchProvider';
import { useMemo } from 'react';
import { links } from '@/config/links';
import { useAuth } from '@/hooks/useAuth';

const GlobalSearchCommand = () => {
	const { results, isLoading, open, setOpen, recentCampaigns, search, setSearch } =
		useGlobalSearch();
	return (
		<CommandDialog open={open} onOpenChange={setOpen}>
			<CommandInput
				placeholder="Search across your campaigns, events, etc..."
				value={search}
				onValueChange={setSearch}
			/>
			<CommandList>
				{isLoading ? (
					<Command.Loading className="p-10 text-center text-muted-foreground text-xs">
						Hang on, we are searching for you...
					</Command.Loading>
				) : (
					results?.length > 0 && <ResultsOptions results={results} />
				)}
				<DefaultOptions recentCampaigns={recentCampaigns} hide={setOpen} />
			</CommandList>
		</CommandDialog>
	);
};

export default GlobalSearchCommand;

const DefaultOptions = ({ recentCampaigns, hide }) => {
	const navigate = useNavigate();
	const { logout } = useAuth();

	const defaultOptions = useMemo(() => {
		return [
			{
				heading: 'Recent Campaigns',
				items: recentCampaigns?.map((i) => ({
					banner: i.banner || 'a',
					name: `Campaign: ${i.name}`,
					status: i.status,
					onSelect: () => {},
				})),
			},
			{
				heading: 'Quick Core Actions',
				items: [
					{
						icon: 'bi bi-plus-circle',
						name: 'Create a quest',
						onSelect: () => {
							navigate('/app/campaign/quests/new');
						},
					},
					{
						icon: 'bi bi-card-checklist',
						name: 'Add a new task in loyalty program',
						onSelect: () => {
							navigate('/app/campaign/loyalty?new=true');
						},
					},
					{
						icon: 'bi bi-file-earmark-plus',
						name: 'Add a new NFT contract',
						onSelect: () => {
							navigate('/app/tools/nfts?new=true');
						},
					},
					{
						icon: 'bi bi-calendar-event',
						name: 'Launch a new event',
						onSelect: () => {
							navigate('/app/campaign/events/new');
						},
					},
					{
						icon: 'bi bi-graph-up',
						name: 'Check leaderboard',
						onSelect: () => {
							navigate('/app/leaderboard');
						},
					},
					{
						icon: 'bi bi-gear',
						name: 'Create DAVE action for verifying on-chain tasks in quests',
						onSelect: () => {
							navigate('/app/tools/dave/action/new');
						},
					},
					{
						icon: 'bi bi-tools',
						name: 'Integrate tasks for verifying tasks in quests',
						onSelect: () => {
							navigate('/app/tools/task-apis?new=true');
						},
					},
					{
						icon: 'bi bi-person-lines-fill',
						name: 'Reach out to KOL from our marketplace',
						onSelect: () => {
							navigate('/app/boosters/kol-marketplace');
						},
					},
					{
						icon: 'bi bi-person-hearts',
						name: 'Reach out to other projects for partnered quests',
						onSelect: () => {
							navigate('/app/boosters/partnerships');
						},
					},
					{
						icon: 'bi bi-person-hearts',
						name: 'Connect your API for task verification',
						onSelect: () => {
							navigate('/app/tools/task-apis?new=true');
						},
					},
				],
			},
			{
				heading: 'Guides',
				items: [
					{
						icon: 'bi bi-trophy',
						name: 'How to launch quests using best practices',
						onSelect: () => {
							window.open(links.docs.campaigns, '_blank');
						},
					},
					{
						icon: 'bi bi-puzzle',
						name: 'How to use DAVE, our no code task verification engine',
						onSelect: () => {
							window.open(links.docs.dave, '_blank');
						},
					},
					{
						icon: 'bi bi-graph-up-arrow',
						name: 'How to build your points system using community hub',
						onSelect: () => {
							window.open(links.docs.loyalty, '_blank');
						},
					},
					{
						icon: 'bi bi-link-45deg',
						name: 'How to integrate API for verifying tasks in quests',
						onSelect: () => {
							window.open(links.docs.taskAPIs, '_blank');
						},
					},
					{
						icon: 'bi bi-box-arrow-up',
						name: 'How data exports in Intract work',
						onSelect: () => {
							window.open(links.docs.exports, '_blank');
						},
					},
					{
						icon: 'bi bi-discord',
						name: 'How to integrate discord and its use cases',
						onSelect: () => {
							window.open(links.docs.discordIntegration, '_blank');
						},
					},
					{
						icon: 'bi bi-telegram',
						name: 'How to integrate telegram and its use cases',
						onSelect: () => {
							window.open(links.docs.telegramIntegration, '_blank');
						},
					},
					{
						icon: 'bi bi-megaphone',
						name: 'How to boost participants using KOL marketplace and partnership with other projects',
						onSelect: () => {
							window.open(links.docs.kols, '_blank');
						},
					},
				],
			},
			{
				heading: 'Settings',
				items: [
					{
						icon: 'bi bi-ui-checks',
						name: 'Customize your hub',
						onSelect: () => {
							navigate('/app/customise-hub/profile');
						},
					},
					{
						icon: 'bi bi-coin',
						name: 'Add your token',
						onSelect: () => {
							navigate('/app/customise-hub/tracked-tokens?new=true');
						},
					},
					{
						icon: 'bi bi-file-earmark-code',
						name: 'Add your contract',
						onSelect: () => {
							navigate(
								'/app/customise-hub/tracked-contracts?new=true',
							);
						},
					},
					{
						icon: 'bi bi-cpu-fill',
						name: 'API Tokens',
						onSelect: () => {
							navigate('/app/profile/api-tokens');
						},
					},
					{
						icon: 'bi bi-box-arrow-up',
						name: 'View data exports',
						onSelect: () => {
							navigate('/app/profile/exports');
						},
					},
					{
						icon: 'bi bi-person-plus-fill',
						name: 'Invite team member',
						onSelect: () => {
							navigate('/app/profile/team?new=true');
						},
					},
					{
						icon: 'bi bi-plus-square',
						name: 'Add another project',
						onSelect: () => {
							navigate(location.pathname + '?create-enterprise=true');
						},
					},
					{
						icon: 'bi bi-box-arrow-right',
						name: 'Log out',
						onSelect: () => {
							logout();
						},
					},
				],
			},
		];
	}, [recentCampaigns]);

	return (
		<>
			{defaultOptions.map((option) => (
				<CommandGroup key={option.heading} heading={option.heading}>
					{option.items.map((item) => (
						<CommandItem
							key={item.name}
							value={item.name}
							onSelect={() => {
								item?.onSelect?.();
								hide(false);
							}}
						>
							{item.icon && <i className={`${item.icon} me-2`} />}
							{item.banner && (
								<Media
									src={item.banner}
									className="h-6 bg-slate-300 w-8 object-cover me-2 rounded border"
								/>
							)}
							{item.name}
							{item.status && (
								<StatusBadge
									status={item.status}
									className="ms-auto"
								/>
							)}
						</CommandItem>
					))}
				</CommandGroup>
			))}
		</>
	);
};

const ResultsOptions = ({ results }: { results: GlobalSearchResult[] }) => {
	if (results?.length === 0) {
		return (
			<CommandGroup heading="No results found">
				<CommandItem>Try searching for something else</CommandItem>
			</CommandGroup>
		);
	}
	return (
		<>
			{results?.filter((i) => i.type === 'campaign').length > 0 && (
				<>
					<CommandGroup heading="Campaigns">
						{results
							?.filter((i) => i.type === 'campaign')
							.map((result) => (
								<ResultItem
									key={result._id}
									name={result.name}
									type={result.type}
									banner={result.banner}
									status={result.status}
									_id={result._id}
								/>
							))}
					</CommandGroup>
				</>
			)}
			<CommandSeparator />
			{results?.filter((i) => i.type === 'event').length > 0 && (
				<CommandGroup heading="Events">
					{results
						?.filter((i) => i.type === 'event')
						.map((result) => (
							<ResultItem
								key={result._id}
								name={result.name}
								type={result.type}
								banner={result.banner}
								status={result.status}
								_id={result._id}
							/>
						))}
				</CommandGroup>
			)}
		</>
	);
};

const ResultItem = ({ name, type, banner, status, _id }: GlobalSearchResult) => {
	const navigate = useNavigate();
	return (
		<>
			<CommandItem
				onSelect={() => {
					if (type === 'event') {
						navigate(`/app/campaign/events/${_id}`);
					} else {
						navigate(`/app/campaign/quests/${_id}`);
					}
				}}
				value={name}
				className="flex justify-between"
			>
				<div className="flex items-center">
					<Media
						src={banner}
						className="h-6 w-10 rounded object-cover me-3"
					/>
					<div>
						<div className="font-medium">{name}</div>
					</div>
				</div>
				<div>
					<StatusBadge status={status} />
				</div>
			</CommandItem>
		</>
	);
};
