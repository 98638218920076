import { DotsHorizontalIcon } from '@radix-ui/react-icons';

import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';

interface IDropdownOptions {
	type: string | 'item' | 'separator';
	label?: string;
	onClick?: any;
	icon?: string;
	disabled?: boolean;
}

const DotsDropdown = ({
	options,
	className,
}: {
	options: IDropdownOptions[];
	className?: string;
}) => {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					variant="outline"
					size="icon"
					className="data-[state=open]:bg-muted h-9 w-6"
				>
					<i className="bi-three-dots-vertical"></i>
					<span className="sr-only">Open menu</span>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end" className={cn('w-[160px]', className)}>
				{options.map((i) => (
					<>
						{i.type === 'item' && (
							<DropdownMenuItem
								onClick={i.onClick}
								disabled={i.disabled}
							>
								{i.icon ? <i className={i.icon}></i> : null}
								{i.label}
							</DropdownMenuItem>
						)}
						{i.type === 'separator' && <DropdownMenuSeparator />}
					</>
				))}
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default DotsDropdown;
