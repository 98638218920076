import { useQuery } from '@tanstack/react-query';
import { fetchGlobalConfigs } from '../services/home.service';

export const useGetGlobalConfigs = () => {
	const { data, isLoading } = useQuery({
		queryKey: ['global-configs'],
		queryFn: () => fetchGlobalConfigs(),
	});

	return {
		globalConfigs: data?.configs || {},
		isLoading,
	};
};
