import { ILoyaltyTask } from '../../types/loyalty.type';
import { Badge } from '@/components/ui/badge';
import StatusBadge from '@/components/element/badges/StatusBadge';
import dayjs from 'dayjs';
import { ITask, Status } from '@/features/campaigns/types';
import { useMemo } from 'react';
import { MetricBasedXpFunction } from '@/features/campaigns/types/tasks.enums';
import {
	LinearGraph,
	SigmoidGraph,
	TieredGraph,
} from '@/features/campaigns/components/create-campaign/tasks/components/Tasks/features/GraphTypes';
import { cn } from '@/lib/utils';

const TaskData = ({
	task,
	status,
	src,
}: {
	task: ILoyaltyTask | ITask;
	status?: Status;
	src?: string;
}) => {
	const frequency = useMemo(() => {
		const isRecurring = task?.recurrenceConfig?.isRecurring;
		if (!isRecurring) return;
		const frequency = task?.recurrenceConfig?.frequencyInDays;
		if (frequency === 1) return 'daily';
		if (frequency === 7) return 'weekly';
		if (frequency === 30) return 'monthly';
		return `every ${frequency} day`;
	}, [task]);

	return (
		<div className="py-4">
			{status && (
				<div className="grid grid-cols-12 items-center justify-center py-3">
					<div className="col-span-5 text-sm text-muted-foreground font-medium">
						<i className="bi-clock-fill me-2"></i>
						Status
					</div>
					<div className="col-span-7">
						<Badge variant="outline">
							<StatusBadge status={status} />
						</Badge>
					</div>
				</div>
			)}
			<div className="grid grid-cols-12 items-center justify-center py-3">
				<div className="col-span-5 text-sm text-muted-foreground font-medium">
					<i className="bi-person-fill me-2"></i>
					Initiations
				</div>
				<div className="col-span-7 text-sm font-medium">
					{task?.initiatedUsersCount || 'No initiations yet'}
				</div>
			</div>
			<div className="grid grid-cols-12 items-center justify-center py-3">
				<div className="col-span-5 text-sm text-muted-foreground font-medium">
					<i className="bi-check-circle-fill me-2"></i>
					Completions
				</div>
				<div className="col-span-7 text-sm font-medium">
					{task?.completedUsersCount || 'No completions yet'}
				</div>
			</div>

			<div className="grid grid-cols-12 items-center justify-center py-3">
				<div className="col-span-5 text-sm text-muted-foreground font-medium">
					<i className="bi-stars me-2"></i>
					XPs
				</div>
				<div className="col-span-7 text-sm font-medium">
					{task?.xp} XP {frequency ? `/ ${frequency}` : ''}
				</div>
			</div>
			{task.maxFailedCountConfig?.isMaxFailedCountEnabled && (
				<div className="grid grid-cols-12 items-center justify-center py-3">
					<div className="col-span-5 text-sm text-muted-foreground font-medium">
						<i className="bi-slash-circle me-2"></i>
						Max Attempt Limit
					</div>
					<div className="col-span-7 text-sm font-medium">
						{task?.maxFailedCountConfig?.maxFailedCount} attempts{' '}
						{task?.maxFailedCountConfig?.isDiminishingXpEnabled
							? ' with diminishing XPs'
							: ''}
					</div>
				</div>
			)}
			{task &&
				task?.metricBasedXpConfig &&
				task?.metricBasedXpConfig?.functionType !==
					MetricBasedXpFunction.None && (
					<div
						className={cn(
							'flex space-x-4 py-3 w-full justify-between',
							src === 'campaign-report' ? 'flex-col' : 'flex-row',
						)}
					>
						<div className="text-sm text-muted-foreground font-medium">
							<i className="bi-graph-up me-2"></i>
							Metric Based XPs
						</div>
						<div
							className={cn(
								'text-sm font-medium justify-end',
								src === 'campaign-report' ? 'mt-4' : '',
							)}
						>
							{task?.metricBasedXpConfig?.functionType ===
							MetricBasedXpFunction.Linear ? (
								<LinearGraph
									xpMultiplier={
										task?.metricBasedXpConfig?.linearMetadata
											?.xpMultiplier
									}
									maxXP={task.xp}
									width={370}
									height={200}
								/>
							) : null}
							{task?.metricBasedXpConfig?.functionType ===
							MetricBasedXpFunction.Tiered ? (
								<TieredGraph
									metricBasedXpConfig={task.metricBasedXpConfig}
									width={370}
									height={200}
								/>
							) : null}
							{task?.metricBasedXpConfig?.functionType ===
							MetricBasedXpFunction.Sigmoid ? (
								<SigmoidGraph
									optimalMetricValue={
										task.metricBasedXpConfig?.sigmoidMetadata
											?.optimalMetricValue
									}
									rateOfIncrease={
										task.metricBasedXpConfig?.sigmoidMetadata
											?.rateOfIncrease
									}
									maxXP={task.xp}
									width={370}
									height={200}
								/>
							) : null}
						</div>
					</div>
				)}

			{
				<>
					{status === Status.Active && (
						<div className="grid grid-cols-12 items-center justify-center py-3">
							<div className="col-span-5 text-sm text-muted-foreground font-medium">
								<i className="bi-calendar me-2"></i>
								End Date
							</div>
							<div className="col-span-7 text-sm font-medium">
								{task?.noEndTime
									? 'No Deadline'
									: dayjs(task?.taskEndDate).fromNow(true)}
							</div>
						</div>
					)}
					{status === Status.Scheduled && (
						<div className="grid grid-cols-12 items-center justify-center py-3">
							<div className="col-span-5 text-sm text-muted-foreground font-medium">
								<i className="bi-calendar me-2"></i>
								Start in
							</div>
							<div className="col-span-7 text-sm font-medium">
								{dayjs(task?.taskStartDate).fromNow(true)}
							</div>
						</div>
					)}
					{status === Status.Completed && (
						<div className="grid grid-cols-12 items-center justify-center py-3">
							<div className="col-span-5 text-sm text-muted-foreground font-medium">
								<i className="bi-calendar me-2"></i>
								Ended at
							</div>
							<div className="col-span-7 text-sm font-medium">
								{dayjs(task?.taskEndDate).fromNow(true)}
							</div>
						</div>
					)}
				</>
			}
		</div>
	);
};

export default TaskData;
