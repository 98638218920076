import { QuestOfTheDayStatus } from '@/features/admin/types/cms.types';
import { CustomTokenStatus } from '@/features/campaigns/hooks/tokens/useCreateToken';
import { POWVerifyStatus, Status } from '@/features/campaigns/types';
import { TaskStatus } from '@/features/campaigns/types/tasks.enums';
import { AlphaHubNarrativeStatus } from '@/features/events/types/events.type';
import { IntegrationStatus } from '@/features/integrations/types/integrations.types';
import { RewardStatus } from '@/features/reward-store/types/reward-store.enums';
import { TaskAPIStatus } from '@/features/task-apis/types/task-apis.enum';
import { cn } from '@/lib/utils';
import { getSentenceCase, toTitleCase } from '@/utils/parsers';

const StatusBadge = ({
	status,
	className,
}: {
	status:
		| Status
		| AlphaHubNarrativeStatus
		| TaskStatus
		| TaskAPIStatus
		| string
		| POWVerifyStatus
		| CustomTokenStatus
		| IntegrationStatus
		| QuestOfTheDayStatus;
	className?: string;
}) => {
	const statusColor = {
		[Status.Active]: 'text-green-500',
		[Status.Scheduled]: 'text-orange-500',
		[Status.Draft]: 'text-gray-500',
		[Status.Completed]: 'text-green-700',
		[Status.InReview]: 'text-yellow-500',
		[Status.Inactive]: 'text-yellow-500',
		[Status.Paused]: 'text-red-500',
		[TaskAPIStatus.Approved]: 'text-green-300',
		[TaskAPIStatus.Rejected]: 'text-red-500',
		[TaskAPIStatus.Submitted]: 'text-yellow-500',
		[TaskAPIStatus.UnderReview]: 'text-amber-500',
		[POWVerifyStatus.Failed]: 'text-red-500',
		[POWVerifyStatus.Initiated]: 'text-yellow-500',
		[POWVerifyStatus.Verified]: 'text-green-500',
		[CustomTokenStatus.Pending]: 'text-yellow-500',
		[IntegrationStatus.Connected]: 'text-green-500',
		[IntegrationStatus.Syncing]: 'text-yellow-500',
		[IntegrationStatus.Error]: 'text-red-500',
		[QuestOfTheDayStatus.Upcoming]: 'text-yellow-500',
	};

	const statusIcon = {
		[Status.Active]: 'bi-circle-fill',
		[Status.Scheduled]: 'bi-circle-fill',
		[Status.Draft]: 'bi-circle-fill',
		[Status.Completed]: 'bi-circle-fill',
		[Status.InReview]: 'bi-circle-fill',
		[Status.Inactive]: 'bi-circle-fill',
		[Status.Paused]: 'bi-circle-fill',
		[TaskAPIStatus.Approved]: 'bi-circle-fill',
		[TaskAPIStatus.Rejected]: 'bi-circle-fill',
		[TaskAPIStatus.Submitted]: 'bi-circle-fill',
		[TaskAPIStatus.UnderReview]: 'bi-circle-fill',
		[POWVerifyStatus.Failed]: 'bi-circle-fill',
		[POWVerifyStatus.Initiated]: 'bi-circle-fill',
		[POWVerifyStatus.Verified]: 'bi-circle-fill',
		[CustomTokenStatus.Pending]: 'bi-circle-fill',
		[IntegrationStatus.Connected]: 'bi-circle-fill',
		[IntegrationStatus.Syncing]: 'bi-circle-fill',
		[IntegrationStatus.Error]: 'bi-circle-fill',
		[QuestOfTheDayStatus.Upcoming]: 'bi-circle-fill',
	};

	const label = {
		[Status.Active]: 'Active',
		[Status.Scheduled]: 'Scheduled',
		[Status.Draft]: 'Draft',
		[Status.Completed]: 'Completed',
		[Status.InReview]: 'In Review',
		[Status.Inactive]: 'Inactive',
		[Status.Paused]: 'Paused',
		[TaskAPIStatus.Approved]: 'Approved',
		[TaskAPIStatus.Rejected]: 'Rejected',
		[TaskAPIStatus.Submitted]: 'Submitted',
		[TaskAPIStatus.UnderReview]: 'Under Review',
		[POWVerifyStatus.Failed]: 'Rejected',
		[POWVerifyStatus.Initiated]: 'Submitted',
		[POWVerifyStatus.Verified]: 'Approved',
		[CustomTokenStatus.Pending]: 'Pending',
		[IntegrationStatus.Connected]: 'Connected',
		[IntegrationStatus.Syncing]: 'Syncing',
		[IntegrationStatus.Error]: 'Error',
		[QuestOfTheDayStatus.Upcoming]: 'Upcoming',
	};

	return (
		<div className={cn('text-xs', className)}>
			<i
				className={`bi ${statusIcon[status]} ${statusColor[status]} mr-1`}
			></i>
			{label[status]}
		</div>
	);
};

export default StatusBadge;
