import { Button } from '@/components/ui/button';
import RewardTokenSelect from '../../input/RewardTokenSelect';
import { useGetAllTokensForEnterprise } from '@/features/campaigns/hooks/tokens/useGetAllTokens';
import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';
import { SelectNetwork2 } from '@/components/element/inputs/SelectNetworks2';
import ImportTokenDialog from './ImportTokenDialog';
import { useState } from 'react';
import { ICampaignAddReward } from '@/features/campaigns/types';

export const SelectTokenAndChain = ({
	tokenReward,
	setTokenReward,
	errors,
}: {
	tokenReward: ICampaignAddReward;
	setTokenReward: any;
	errors: {
		method: boolean;
		chain: boolean;
		token: boolean;
		winners: boolean;
		reward: boolean;
		expected: boolean;
	};
}) => {
	const [open, setOpen] = useState(false);

	const { tokenChains } = useGetSupportedChains({
		showAdditionalChains: false,
	});
	const { tokens } = useGetAllTokensForEnterprise(
		tokenReward.tokenReward?.chainId,
	);

	return (
		<div className="mt-5 mb-6">
			<div className="grid px-6 grid-cols-2 gap-2  pb-0 mb-0">
				<div>
					<div className="text-sm font-medium mb-1">
						Select the Network
					</div>
					<SelectNetwork2
						value={tokenReward?.tokenReward?.chainId}
						setValue={({ chainId }) => {
							setTokenReward({
								...tokenReward,
								tokenReward: {
									...tokenReward.tokenReward,
									chainId: chainId,
									tokenAddress: '',
								},
							});
						}}
						allowedChainIds={
							tokenChains?.map((chain) => Number(chain?.chainId)) ?? []
						}
						error={errors?.chain}
						errorMsg="Please select a network"
						defaultAll={false}
					/>
				</div>
				<div>
					<div className="text-sm font-medium mb-1">
						Select the ERC20 token
					</div>
					<RewardTokenSelect
						tokens={tokens}
						value={tokenReward?.tokenReward?.tokenAddress}
						setValue={(address: string) => {
							const token = tokens.find((i) => i.address === address);
							setTokenReward({
								...tokenReward,
								tokenReward: {
									...tokenReward.tokenReward,
									tokenAddress: token.address,
									tokenSymbol: token.symbol,
									tokenDecimals: token.decimals,
									logo: token.logo,
									name: token.name,
									chainId: token.chainId,
								},
							});
						}}
						error={errors?.token}
						errorText={'Invalid token'}
					/>
				</div>
			</div>
			{tokenReward?.tokenReward?.chainId && (
				<Button
					variant="link"
					className="text-xs p-0 justify-normal font-normal mt-0 px-6 text-muted-foreground"
					onClick={() => setOpen(true)}
				>
					<i className="bi-info-circle mr-1 text-muted-foreground"></i>
					Can't find your token?
				</Button>
			)}
			<ImportTokenDialog
				open={open}
				setOpen={setOpen}
				defaultChainId={tokenReward?.tokenReward?.chainId}
			/>
		</div>
	);
};
