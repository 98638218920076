import { AddTaskBtn } from './components/Templates/AddTaskBtn';
import { Draggable } from 'react-beautiful-dnd';
import TaskDraggableLayout from './components/Layout/TaskDraggableLayout';
import TasksAndTaskGroups from './components/Layout/TasksAndTaskGroups';
import EditTaskLayout from './components/Layout/EditTaskLayout';
import { useCreateCampaignTasks } from '@/features/campaigns/hooks/create/useCreateCampaignTasks';
import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';

const EditTasks = () => {
	const { isReordering } = useCreateCampaign();
	const { tasks, errors } = useCreateCampaignTasks();

	return (
		<div className="w-full">
			<EditTaskLayout>
				<TaskDraggableLayout className="flex flex-col items-center justify-center">
					{tasks &&
						Array.isArray(tasks) &&
						tasks.map((i, index) => {
							return (
								<Draggable
									key={i.id}
									draggableId={i.id}
									index={index}
									isDragDisabled={!isReordering}
								>
									{(provided, snapshot) => {
										return (
											<div
												ref={provided.innerRef}
												className="notranslate"
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												<TasksAndTaskGroups
													task={i}
													isDragging={snapshot.isDragging}
													taskIndex={index}
												/>
											</div>
										);
									}}
								</Draggable>
							);
						})}
				</TaskDraggableLayout>
				{errors?.isEmpty && (
					<div className="text-destructive bg-white text-sm py-3 px-24 mb-4 rounded-lg">
						At least one task is required to create a campaign
					</div>
				)}
				{!isReordering && <AddTaskBtn />}
			</EditTaskLayout>
		</div>
	);
};

export default EditTasks;
