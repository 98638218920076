import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';
import { formatNumberWithCommas } from '@/utils/parsers';

const CampaignsMetrics = ({
	metrics,
	isLoading,
	isTotalUsersLoading,
}: {
	isLoading: boolean;
	isTotalUsersLoading: boolean;
	metrics: {
		totalInitiations: number;
		totalCompletions: number;
		totalUsers: number | string;
	};
}) => {
	if (isLoading && isTotalUsersLoading) {
		return (
			<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 mt-7">
				{[1, 2, 3].map(() => (
					<Skeleton className="w-full h-[114px] rounded-lg" />
				))}
			</div>
		);
	}
	return (
		<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 mt-7">
			<TooltipWrapper
				tooltip={'Users who attempted at least one task'}
				align="start"
			>
				<MetricCard
					label="Total Initiation"
					icon="person-fill"
					metric={metrics.totalInitiations}
				/>
			</TooltipWrapper>
			<TooltipWrapper tooltip={'Users who completed all tasks'} align="end">
				<MetricCard
					label="Total Completions"
					icon="person-fill-check"
					metric={metrics.totalCompletions}
				/>
			</TooltipWrapper>
			{!isTotalUsersLoading ? (
				<TooltipWrapper
					tooltip={'Logged in users who visited the quest page'}
					tooltipContentClass="text-left"
					align="start"
				>
					<MetricCard
						label="Total Users"
						icon="people-fill"
						metric={metrics.totalUsers}
					/>
				</TooltipWrapper>
			) : (
				<Skeleton className="w-full h-[114px] rounded-lg" />
			)}
		</div>
	);
};

export default CampaignsMetrics;

const MetricCard = ({ metric, label, icon }) => (
	<Card>
		<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
			<CardTitle className="text-sm font-medium">{label}</CardTitle>
			<i className={cn('me-2', `bi-${icon}`, '')}></i>
		</CardHeader>
		<CardContent>
			<div className="text-2xl font-bold text-left">
				{formatNumberWithCommas(metric)}
			</div>
		</CardContent>
	</Card>
);
