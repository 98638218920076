import InputRadioCards from '@/components/element/inputs/InputRadioCards';
import InputText from '@/components/element/inputs/InputText';
import { Separator } from '@/components/ui/separator';
import { Switch } from '@/components/ui/switch';
import {
	ICreateCampaignDetails,
	ReferralRewardType,
} from '@/features/campaigns/types';
import { cn } from '@/lib/utils';
import React from 'react';

const QuestReferralConfig = ({
	details,
	setDetails,
	errors,
}: {
	details: ICreateCampaignDetails;
	setDetails: React.Dispatch<React.SetStateAction<ICreateCampaignDetails>>;
	errors: {
		[key: string]: boolean | string;
	};
}) => {
	return (
		<div className="mt-14">
			<div className="mb-3 flex justify-between">
				<div>
					<div className="text-sm font-medium">
						<i className="bi-person-heart me-2"></i>
						Configure Referral for your Quest
					</div>
					<div className="text-xs text-muted-foreground mt-1">
						Let users invite friends to earn more reward
					</div>
				</div>
				<div>
					<Switch
						checked={details.referral.referralTaskEnabled}
						onCheckedChange={(checked) => {
							setDetails((prev) => ({
								...prev,
								referral: {
									...prev.referral,
									referralTaskEnabled: checked,
								},
							}));
						}}
					/>
				</div>
			</div>
			{details.referral.referralTaskEnabled && (
				<div className="mt-6 bg-white p-5 rounded-lg">
					<div>
						<div className=" mb-2 text-sm font-medium">
							How do you want to reward your users?
						</div>
						<InputRadioCards
							options={[
								{
									icon: 'bi-lock',
									label: 'Fixed Reward',
									isChecked:
										details?.referral?.rewardType ===
										ReferralRewardType.Fixed,
									setIsChecked: (val) =>
										setDetails((prev) => ({
											...prev,
											referral: {
												...prev.referral,
												rewardType: ReferralRewardType.Fixed,
											},
										})),
									className: 'p-3',
								},
								{
									icon: 'bi-unlock',
									label: 'Flexible Reward',
									isChecked:
										details?.referral?.rewardType ===
										ReferralRewardType.Flexible,
									setIsChecked: (val) =>
										setDetails((prev) => ({
											...prev,
											referral: {
												...prev.referral,
												rewardType:
													ReferralRewardType.Flexible,
											},
										})),
									className: 'p-3',
								},
							]}
							colSize={2}
						/>
					</div>
					{!!errors?.rewardType && (
						<p className={cn('text-xs  text-destructive mt-1')}>
							{errors?.rewardType}
						</p>
					)}
					<div className="-mx-5">
						<Separator className="mt-5 " />
					</div>
					<div className="mt-5 mb-2 text-sm font-medium">
						Reward for Referrers
					</div>
					<div className="grid grid-cols-12 gap-4 second-element ">
						<InputText
							prepend={
								<div
									className={`px-3 text-sm ${details.referral.rewardType !== ReferralRewardType.Flexible ? 'w-[190px]' : ''}`}
								>
									{details.referral.rewardType ===
									ReferralRewardType.Flexible
										? 'Every'
										: 'Number of Referrals >='}
								</div>
							}
							append={
								details.referral.rewardType ===
									ReferralRewardType.Flexible && (
									<div className="px-3 text-sm ">
										{details.referral.rewardType ===
										ReferralRewardType.Flexible
											? 'Referral(s)'
											: ''}
									</div>
								)
							}
							placeholder="Launch on Intract"
							className="col-span-8"
							value={Number(
								details?.referral?.numberOfRequiredReferrals,
							)}
							setValue={(e) => {
								const numericValue = Number(e);
								if (Number.isNaN(numericValue)) {
									return;
								}
								setDetails((prev) => ({
									...prev,
									referral: {
										...prev.referral,
										numberOfRequiredReferrals: Number(e),
									},
								}));
							}}
							inputClassName="bg-white dark:bg-black"
							error={!!errors?.numberOfRequiredReferrals}
							errorText={errors?.numberOfRequiredReferrals}
						/>
						<InputText
							append={<div className="text-sm px-4">XPs</div>}
							placeholder="100"
							className="col-span-4"
							value={Number(details?.referral?.rewardValue)}
							setValue={(e) => {
								const numericValue = Number(e);
								if (Number.isNaN(numericValue)) {
									return;
								}
								setDetails((prev) => ({
									...prev,
									referral: {
										...prev.referral,
										rewardValue: Number(e),
									},
								}));
							}}
							inputClassName="bg-white dark:bg-black"
							errorText={errors?.rewardValue}
							error={!!errors?.rewardValue}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default QuestReferralConfig;
