import { useState } from 'react';
import { ICampaign, Status } from '../../types';
import { handleErrorMessage } from '@/utils/notifications';
import {
	deleteCampaign,
	exportPoWSubmissions,
	exportWinners,
	unscheduleCampaign,
	updateCampaignStatus,
} from '../../services/campaigns.service';
import { useNavigate, useParams } from 'react-router-dom';
import { queryClient } from '@/lib/react-query';
import { QUEST_URL } from '@/config';
import { toast } from 'sonner';
import { exportLeaderboards } from '@/features/leaderboard/services/leaderboard.service';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { useMutation } from '@tanstack/react-query';

export const useCampaignActions = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState({
		delete: false,
		publishTest: false,
		preview: false,
		convertToDraft: false,
		duplicate: false,
		stop: false,
		exporting: false,
	});

	const handleDeleteCampaign = async (campaignId: string) => {
		try {
			toast.promise(
				Promise.all([deleteCampaign(campaignId)]).then(async () => {
					await queryClient.invalidateQueries({
						queryKey: ['campaign', campaignId],
					});
					const data = (await queryClient.getQueryData([
						'campaigns',
					])) as ICampaign[];
					await queryClient.setQueryData(
						['campaigns'],
						data.filter((campaign: any) => campaign._id !== campaignId),
					);
				}),
				{
					loading: 'Deleting campaign...',
					success: 'Campaign deleted successfully',
					error: 'Failed to delete campaign',
				},
			);
		} catch (err) {
			console.log(err);
			handleErrorMessage(err);
		}
	};

	const previewCampaign = (campaignId: string) => {
		analytics.track(TrackingEvents.QuestReportActions, {
			actionType: 'preview_campaign',
		});
		window.open(`${QUEST_URL}/quest/${campaignId}`, '_blank');
	};

	const convertToDraft = async (campaignId: string, status: Status) => {
		if (status !== Status.InReview) return;
		try {
			setIsLoading({ ...isLoading, convertToDraft: true });
			await updateCampaignStatus(campaignId, {
				newStatus: Status.Draft,
			});
			analytics.track(TrackingEvents.QuestReportActions, {
				actionType: 'convert_to_draft',
			});
			await queryClient.invalidateQueries({
				queryKey: ['campaign', campaignId],
			});
			const existingTableData = (await queryClient.getQueryData([
				'campaigns',
			])) as ICampaign[];
			if (existingTableData) {
				await queryClient.setQueryData(
					['campaigns'],
					(existingTableData as any[]).map((item) => {
						if (item._id === campaignId) {
							return {
								...item,
								status: Status.Draft,
								updatedAt: new Date().toISOString(),
							};
						}
						return item;
					}),
				);
			}
			navigate(`/app/campaign/quests/${campaignId}/update`, {
				replace: id ? true : false,
			});
			setIsLoading({ ...isLoading, convertToDraft: false });
		} catch (err) {
			setIsLoading({ ...isLoading, convertToDraft: false });
			console.log(err);
			handleErrorMessage(err);
		}
	};

	const duplicateCampaign = (campaignId: string) => {
		analytics.track(TrackingEvents.QuestReportActions, {
			actionType: 'duplicate_campaign',
		});
		navigate(`/app/campaign/quests/new?duplicateFrom=${campaignId}`);
	};

	const stopCampaign = (campaignId: string) => {};
	const restartCampaign = (campaignId: string) => {};

	const downloadLeaderboard = async (campaignId: string) => {
		try {
			analytics.track(TrackingEvents.QuestReportActions, {
				actionType: 'export_leaderboard',
			});
			setIsLoading({ ...isLoading, exporting: true });
			await exportLeaderboards({ campaignId });
			toast.success(
				'Exporting leaderboard. You will receive an email shortly.',
			);
			setIsLoading({ ...isLoading, exporting: false });
		} catch (err) {
			setIsLoading({ ...isLoading, exporting: false });
			handleErrorMessage(err);
		}
	};
	const downloadWinners = async (campaignId: string) => {
		try {
			analytics.track(TrackingEvents.QuestReportActions, {
				actionType: 'export_winners',
			});
			setIsLoading({ ...isLoading, exporting: true });
			await exportWinners(campaignId);
			toast.success('Exporting winners. You will receive an email shortly.');
			setIsLoading({ ...isLoading, exporting: false });
		} catch (err) {
			setIsLoading({ ...isLoading, exporting: false });
			handleErrorMessage(err);
		}
	};
	const downloadSubmissions = async (campaignId: string) => {
		try {
			analytics.track(TrackingEvents.QuestReportActions, {
				actionType: 'export_submissions',
			});
			setIsLoading({ ...isLoading, exporting: true });
			await exportPoWSubmissions(campaignId);
			toast.success(
				'Exporting submissions. You will receive an email shortly.',
			);
			setIsLoading({ ...isLoading, exporting: false });
		} catch (err) {
			setIsLoading({ ...isLoading, exporting: false });
			handleErrorMessage(err);
		}
	};
	const unScheduleMutation = useMutation({
		mutationFn: unscheduleCampaign,
		onSuccess: () => {
			toast.success('Campaign un-scheduled successfully');
			queryClient.invalidateQueries({
				queryKey: ['campaign', id],
			});
		},
		onError: () => {
			toast.error('Error un-scheduling campaign');
		},
	});

	const handleUnschedule = async (id: string) => {
		await unScheduleMutation.mutateAsync({
			id,
			data: { newStatus: Status.InReview },
		});
	};

	return {
		deleteCampaign: handleDeleteCampaign,
		previewCampaign,
		convertToDraft,
		downloadLeaderboard,
		downloadWinners,
		duplicateCampaign,
		stopCampaign,
		isLoading,
		setIsLoading,
		downloadSubmissions,
		restartCampaign,
		handleUnschedule,
		isUnScheduleLoading: unScheduleMutation.isPending,
	};
};
