import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { useMemo, useState } from 'react';
import { Separator } from '@/components/ui/separator';
import { useCampaignReport } from '@/features/campaigns/hooks/report/useCampaignReport';
import { convertCamelCaseToSentence } from '@/utils/parsers';
import { cn } from '@/lib/utils';
import { ITask, Status } from '@/features/campaigns/types';
import TaskAdminValue from '../../../create-campaign/tasks/components/Tasks/params/TaskAdminValue';
import TaskData from '@/features/loyalty-campaigns/components/task/TaskData';
import TaskAdminInputsUpdate from '../updates/TaskAdminInputsUpdate';
import DraggableReorderList from '@/components/element/dnd/DraggableReorderList';

const TaskDetails = () => {
	const { campaign, campaignTasks, handleReorder } = useCampaignReport();
	const [showEditTask, setShowEditTask] = useState(false);
	const [selectedTaskId, setSelectedTaskId] = useState<string>(null);

	useMemo(() => {
		setSelectedTaskId(campaignTasks?.[0]?._id);
	}, [campaignTasks]);

	const selectedTask = useMemo(() => {
		const tasks = campaignTasks ?? [];
		return tasks.find((t) => t._id === selectedTaskId);
	}, [selectedTaskId, campaignTasks]);

	return (
		<>
			<Card className="col-span-3 relative">
				<CardHeader className="border-b py-4">
					<div className="flex items-center justify-between">
						<CardTitle className=" font-medium">Task Details</CardTitle>
						<Badge variant="secondary">
							{campaignTasks.length} Tasks
						</Badge>
					</div>
				</CardHeader>
				<div className="flex">
					<CardContent className="p-3 flex justify-between w-[350px] border-e">
						<div className="w-full">
							<DraggableReorderList
								items={campaignTasks.map((task) => ({
									id: task._id,
								}))}
								onReorder={handleReorder}
							>
								{campaignTasks.map((task: ITask) => (
									<Button
										className={`px-3 justify-start truncate w-full mb-2 ${
											selectedTaskId === task._id
												? 'bg-slate-100'
												: 'text-primary'
										}`}
										variant="ghost"
										onClick={() => setSelectedTaskId(task?._id)}
										key={task._id}
									>
										{task?.logo?.type === 'icon' && (
											<i
												className={`bi ${task.logo?.value} text-blue-600 me-2 text-lg`}
											></i>
										)}
										{task?.logo?.type === 'image' && (
											<img
												src={task.logo?.value}
												alt=""
												className="h-6 me-2"
											/>
										)}
										<div className="flex-grow truncate text-start">
											{task?.description}
										</div>
									</Button>
								))}
							</DraggableReorderList>
						</div>
					</CardContent>
					{selectedTaskId && (
						<CardContent className="p-5 flex  bg-slate-50 m-3 min-h-[300px] rounded-lg w-full relative flex-col">
							<div className="flex justify-between items-start">
								<div>
									<div>
										<div className="text-base font-semibold">
											{selectedTask?.description}
										</div>
									</div>
									<div className="text-xs text-muted-foreground mt-1">
										This is a{' '}
										{convertCamelCaseToSentence(
											selectedTask?.templateType,
										)}{' '}
										task
									</div>
								</div>
								{(campaign?.status === Status.Active ||
									campaign?.status === Status.Scheduled) && (
									<div className="flex space-x-3">
										<Button
											size="icon"
											variant="outline"
											onClick={() => setShowEditTask(true)}
										>
											<i className="bi-pencil-square"></i>
										</Button>
									</div>
								)}
							</div>
							<Separator className="mt-3" />
							<div className="grid grid-cols-11 gap-4 mt-4 h-full">
								<div className=" col-span-6 border-e pe-10">
									<div className="text-sm font-medium mb-5">
										Task Parameters
									</div>
									{selectedTask.adminInputs.map((i) => (
										<div className="text-sm mb-6">
											<div className="text-muted-foreground  text-xs font-medium mb-1">
												{i.label}
											</div>
											<div
												className={cn(
													'font-medium text-sm truncate',
													i.value
														? ''
														: 'text-muted-foreground',
												)}
											>
												<TaskAdminValue adminInput={i} />
											</div>
										</div>
									))}
								</div>
								<div className="space-y-4 col-span-5 h-full -my-4">
									<TaskData
										task={selectedTask}
										src="campaign-report"
									/>
								</div>
							</div>
						</CardContent>
					)}
				</div>
			</Card>
			<TaskAdminInputsUpdate
				open={showEditTask}
				setOpen={setShowEditTask}
				task={selectedTask}
			/>
		</>
	);
};

export default TaskDetails;
