import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import DashboardLayout from '@/features/dashboard/components/layout/DashboardLayout';
import GettingStartedRoutes from '@/features/getting-started/routes/getting-started.route';
import CampaignRoutes from '@/features/campaigns/routes/campaigns.route';
import TaskAPIsRoutes from '@/features/task-apis/routes/task-apis.route';
import LoyaltyCampaignsRoute from '@/features/loyalty-campaigns/routes/loyalty-campaigns.route';
import EventsRoutes from '@/features/events/routes/events.route';
import LeaderboardRoutes from '@/features/leaderboard/routes/leaderboard.route';
import DaveRoutes from '@/features/dave/routes/dave.route';
import NFTContractRoutes from '@/features/nft-contracts/routes/nft-contracts.route';
import CustomiseHubRoutes from '@/features/customise-hub/routes/customise-hub.route';
import IntegrationRoutes from '@/features/integrations/routes/integrations.route';
import RewardHubRoutes from '@/features/reward-hub/routes/reward-hub.route';
import ProfileRoutes from '@/features/profile/routes/profile.route';
import KOLMarketplaceRoutes from '@/features/kol-marketplace/routes/kol-marketplace.route';
import { PartnershipsRoutes } from '@/features/partnerships/routes/partnerships.route';
import { AppError } from '@/components/layout/error/SentryErrorFallback';
import * as Sentry from '@sentry/react';
import { SearchProvider } from '@/providers/SearchProvider';
import GlobalLayout from '@/components/layout/GlobalLayout';
import RewardStoreRoute from '@/features/reward-store/routes/reward-store.route';
import GettingStartedPage from '@/features/getting-started/pages/GettingStartedPage';
import CommunitySettingRoutes from '@/features/community-settings/routes/community-settings.route';
import ExternalAPIs from '@/features/profile/routes/external-apis.page';
import ImportedTokens from '@/features/customise-hub/routes/imported-tokens.page';
import ImportedContracts from '@/features/customise-hub/routes/imported-contracts.page';

function App() {
	const location = useLocation();
	if (location.pathname.includes('new') || location.pathname.includes('update')) {
		return (
			<Sentry.ErrorBoundary fallback={AppError} showDialog>
				<Suspense fallback={<FullPageLoading />}>
					<Outlet />
				</Suspense>
			</Sentry.ErrorBoundary>
		);
	}
	return (
		<Sentry.ErrorBoundary fallback={AppError} showDialog>
			<SearchProvider>
				<DashboardLayout>
					<Suspense fallback={<FullPageLoading />}>
						<Outlet />
					</Suspense>
				</DashboardLayout>
			</SearchProvider>
		</Sentry.ErrorBoundary>
	);
}

export const protectedRoutes = [
	{
		path: '/app',
		element: (
			<GlobalLayout>
				<App />
			</GlobalLayout>
		),
		children: [
			{
				path: '',
				element: <Navigate_ />,
			},
			{
				path: 'leaderboard/*',
				element: <LeaderboardRoutes />,
			},
			{
				path: 'campaign/quests/*',
				element: <CampaignRoutes />,
			},
			{
				path: 'campaign/loyalty/*',
				element: <LoyaltyCampaignsRoute />,
			},
			{
				path: 'campaign/events/*',
				element: <EventsRoutes />,
			},
			{
				path: 'reward-hub/*',
				element: <RewardHubRoutes />,
			},
			{
				path: 'tools/task-apis/*',
				element: <TaskAPIsRoutes />,
			},
			{
				path: 'tools/your-contracts/nfts/*',
				element: <NFTContractRoutes />,
			},
			{
				path: 'tools/your-contracts/imported-contracts/*',
				element: <ImportedContracts />,
			},
			{
				path: 'tools/your-contracts/imported-tokens/*',
				element: <ImportedTokens />,
			},
			{
				path: 'tools/dave/*',
				element: <DaveRoutes />,
			},
			{
				path: 'tools/auth-tokens/*',
				element: <ExternalAPIs />,
			},
			{
				path: 'integrations/*',
				element: <IntegrationRoutes />,
			},
			{
				path: 'profile/*',
				element: <ProfileRoutes />,
			},
			{
				path: 'boosters/kol-marketplace/*',
				element: <KOLMarketplaceRoutes />,
			},
			{
				path: 'boosters/partnerships/*',
				element: <PartnershipsRoutes />,
			},
			{
				path: 'campaign/reward-store/*',
				element: <RewardStoreRoute />,
			},
			{
				path: 'home',
				element: <GettingStartedRoutes />,
			},
			{
				path: 'getting-started',
				element: <GettingStartedPage />,
			},
			{
				path: 'community-settings/*',
				element: <CommunitySettingRoutes />,
			},
			{
				path: '*',
				element: <Navigate_ />,
			},
		],
	},
	{
		path: '*',
		element: <Navigate_ />,
	},
];

function Navigate_() {
	const location = useLocation();
	const searchParams = location.search;
	return <Navigate to={`/app/home${searchParams}`} />;
}
