import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
	HomepageWidgetSources,
	HomepageWidgetTypes,
	IWidgets,
} from '../types/cms.types';
import {
	createWidget,
	getWidgetCampaigns,
	getWidgets,
	updateWidgetCampaigns,
} from '../services/cms.service';
import useAdminWidgets from '../hooks/admin-cms-widgets/useAdminWidgets';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '@/lib/react-query';
import { handleErrorMessage } from '@/utils/notifications';

interface IWidgetCreateProviderState {
	details: IWidgets;
	sections: IWidgets['campaign'];
	setDetails: Dispatch<SetStateAction<IWidgets>>;
	setSections: Dispatch<SetStateAction<IWidgets['campaign']>>;
	isInitializing: boolean;
	setIsInitializing: Dispatch<SetStateAction<boolean>>;
	handleReorder: (newData: IWidgets['campaign']) => void;
	handleSelectWidget: (value: string) => void;
	widgets: IWidgets[];
	isLoading: boolean;
	search: string;
	setSearch: Dispatch<SetStateAction<string>>;
	widgetCampaignData: IWidgets['campaign'];
	showCampaignList: boolean;
	setShowCampaignList: Dispatch<SetStateAction<boolean>>;
	addQuest: (campaign: IWidgets) => void;
	updateQuestsInWidgets: () => void;
	isSubmitting: boolean;
	isPending: boolean;
	createNewWidget: () => void;
	resetForm: () => void;
	formFields: IFormFields;
	setFormFields: Dispatch<SetStateAction<IFormFields>>;
	formErrors: any;
	setFormErrors: Dispatch<SetStateAction<any>>;
	showAddWidget: boolean;
	setShowAddWidget: Dispatch<SetStateAction<boolean>>;
}

export interface IFormFields {
	title: string;
	description: string;
	index: number;
	type: HomepageWidgetTypes;
	source: HomepageWidgetSources;
	displayLimit: number;
	isLoyaltyWidget?: boolean;
}

const initialFormFields = {
	title: '',
	description: '',
	index: 0,
	type: HomepageWidgetTypes.Nft,
	source: HomepageWidgetSources.Quest,
	displayLimit: 10,
	isLoyaltyWidget: false,
};

const defaultDetails = {
	_id: '',
	name: '',
	resourceIds: [],
	campaign: [
		{
			id: '',
			name: '',
			widgetId: '',
		},
	],
};

const initialState: IWidgetCreateProviderState = {
	details: defaultDetails,
	sections: [],
	setDetails: () => {},
	setSections: () => {},
	isInitializing: true,
	setIsInitializing: () => {},
	handleReorder: () => {},
	handleSelectWidget: () => {},
	widgets: [],
	isLoading: false,
	search: '',
	setSearch: () => {},
	widgetCampaignData: [
		{
			id: '',
			name: '',
			widgetId: '',
		},
	],
	showCampaignList: false,
	setShowCampaignList: () => {},
	addQuest: () => {},
	updateQuestsInWidgets: () => {},
	isSubmitting: false,
	isPending: false,
	createNewWidget: () => {},
	resetForm: () => {},
	formFields: initialFormFields,
	setFormFields: () => {},
	formErrors: {},
	setFormErrors: () => {},
	showAddWidget: false,
	setShowAddWidget: () => {},
};

export const WidgetCreateContext =
	createContext<IWidgetCreateProviderState>(initialState);

export const WidgetCreateProvider = ({ children }: { children: JSX.Element }) => {
	const [details, setDetails] = useState<IWidgets>(defaultDetails);
	const [sections, setSections] = useState<IWidgets['campaign']>([]);
	const [isInitializing, setIsInitializing] = useState(true);
	const [showCampaignList, setShowCampaignList] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [formFields, setFormFields] = useState<IFormFields>(initialFormFields);
	const [formErrors, setFormErrors] = useState({});
	const [showAddWidget, setShowAddWidget] = useState(false);

	const { widgets, isLoading, search, setSearch } = useAdminWidgets();

	const { data: widgetCampaignData, isLoading: isWidgetCampaignsLoading } =
		useQuery({
			queryKey: ['cms-widget-campaigns', details?._id],
			queryFn: () =>
				getWidgetCampaigns(
					details?._id,
					details?.resourceIds,
					details?.isLoyaltyWidget,
				),
			enabled: !!details?._id,
		});

	useEffect(() => {
		if (!widgetCampaignData) return;

		setDetails((prev) => ({
			...prev,
			campaign: widgetCampaignData?.map((campaign) => ({
				id: campaign._id,
				name: campaign.title,
				widgetId: details._id,
			})),
		}));

		const tempSections = [];
		for (const campaign of widgetCampaignData) {
			tempSections.push({
				id: campaign._id,
				name: campaign.title,
				widgetId: details._id,
			});
		}
		setSections(tempSections);
	}, [widgetCampaignData, setDetails, details._id, setSections]);

	useEffect(() => {
		setFormErrors((prev) => ({
			...prev,
			title: '',
			type: '',
			source: '',
			displayLimit: '',
		}));
	}, [
		formFields.title,
		formFields.type,
		formFields.source,
		formFields.displayLimit,
	]);

	const handleReorder = async (newData: IWidgets['campaign']) => {
		// Update the local state, later on Save Details update the BE
		setSections(newData);
		setDetails((prev) => ({
			...prev,
			campaign: newData,
		}));
	};
	const handleSelectWidget = (value: string) => {
		const selectedWidget = widgets.find((i) => i._id === value);

		if (selectedWidget) {
			setDetails((prev) => ({
				...prev,
				_id: value,
				name: selectedWidget.title,
				resourceIds: selectedWidget.ids,
				isLoyaltyWidget: selectedWidget.isLoyaltyWidget,
			}));
		}
	};
	const addQuest = (campaign: IWidgets) => {
		setDetails((prev) => ({
			...prev,
			campaign: [
				...prev.campaign,
				{
					id: campaign?._id,
					name: campaign?.name,
					widgetId: details._id,
				},
			],
		}));
		setSections((prev) => [
			{
				id: campaign._id,
				name: campaign.name,
				widgetId: details._id,
			},
			...prev,
		]);
	};

	const updateQuestsInWidgets = async () => {
		setIsSubmitting(true);

		const updatedCampaignIds = details?.campaign
			?.map((item) => item.id)
			.filter((item) => item !== '');

		try {
			await updateWidgetCampaigns(details._id, updatedCampaignIds);
			await Promise.all([
				queryClient.invalidateQueries({
					queryKey: ['cms-widgets'],
					refetchType: 'active',
				}),
				queryClient.invalidateQueries({
					queryKey: ['cms-widget-campaigns', details?._id],
					refetchType: 'active',
				}),
			]);
		} catch (error) {
			handleErrorMessage(error);
		} finally {
			setIsSubmitting(false);
		}
	};
	const resetForm = () => {
		setFormFields(initialFormFields);
		setFormErrors({});
	};
	const handleUpdateSelectedWidget = async (resp) => {
		await Promise.all([
			queryClient.invalidateQueries({
				queryKey: ['cms-widgets'],
				refetchType: 'active',
			}),
			queryClient.invalidateQueries({
				queryKey: ['cms-widget-campaigns', details?._id],
				refetchType: 'active',
			}),
		]);

		// Refetch the widgets data to get the latest state
		const updatedWidgets = await queryClient.fetchQuery({
			queryKey: ['cms-widgets'],
			queryFn: getWidgets,
		});

		setShowAddWidget(false);

		const selectedWidget = updatedWidgets.find(
			(widget) => widget._id === resp._id,
		);
		if (selectedWidget) {
			setDetails((prev) => ({
				...prev,
				_id: resp?._id,
				name: selectedWidget.title,
				resourceIds: selectedWidget.ids,
				isLoyaltyWidget: selectedWidget.isLoyaltyWidget,
			}));
		}
	};

	const createMutation = useMutation({
		mutationFn: createWidget,
		onSuccess: async (res) => {
			resetForm();
			handleUpdateSelectedWidget(res);
		},
		onError: (error) => {
			handleErrorMessage(error);
		},
	});

	const validateForm = () => {
		const errors: any = {};
		if (!formFields.title || !formFields.title.trim()) {
			errors.title = 'Please enter a valid title';
		}
		if (!formFields.type || !formFields.type.trim()) {
			errors.type = 'Please select a valid type';
		}
		if (!formFields.source || !formFields.source.trim()) {
			errors.source = 'Please select a valid source';
		}
		if (formFields.displayLimit && formFields.displayLimit <= 0) {
			errors.displayLimit = 'Should be > 0';
		}

		setFormErrors(errors);

		return Object.keys(errors).length === 0;
	};
	const createNewWidget = async () => {
		if (!validateForm()) return;
		await createMutation.mutateAsync({
			...formFields,
			index: widgets[widgets.length - 1]?.index + 1 || 0,
		});
	};

	return (
		<WidgetCreateContext.Provider
			value={{
				details,
				sections,
				setDetails,
				setSections,
				isInitializing,
				setIsInitializing,
				handleReorder,
				handleSelectWidget,
				widgets,
				isLoading: isLoading || isWidgetCampaignsLoading,
				search,
				setSearch,
				widgetCampaignData,
				showCampaignList,
				setShowCampaignList,
				addQuest,
				updateQuestsInWidgets,
				isSubmitting,
				isPending: createMutation.isPending,
				createNewWidget,
				resetForm,
				formFields,
				setFormFields,
				formErrors,
				setFormErrors,
				showAddWidget,
				setShowAddWidget,
			}}
		>
			{children}
		</WidgetCreateContext.Provider>
	);
};
