import { testAPI } from '../services/task-apis.service';
import { queryClient } from '@/lib/react-query';
import { useMutation } from '@tanstack/react-query';
import { useConnectAPI } from './useConnectAPI';
import { CreateTaskAPISteps } from '../types/task-apis.enum';
import { handleErrorMessage } from '@/utils/notifications';
import { IAPIFormFields } from '../types/task-apis.type';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

const useTestAPI = () => {
	const { formFields, setFormFields, setFormErrors, setIsLoading, step, setStep } =
		useConnectAPI();

	const testApiMutation = useMutation({
		mutationFn: testAPI,
		onSuccess: async (res) => {
			setFormFields((prev) => ({
				...prev,
				isApiTestDone: true,
				isApiSchemaValid: res.isResponseSchemaValid,
				responseSchema: res.response,
			}));
			setIsLoading(false);
			analytics.track(TrackingEvents.TaskAPIsCreateTested, {
				isValid: res.isResponseSchemaValid,
			});
			if (step === CreateTaskAPISteps.Testing && res.isResponseSchemaValid) {
				setStep(CreateTaskAPISteps.Upsert);
			}
			await queryClient.invalidateQueries({
				queryKey: ['api-requests'],
			});
		},
		onError: (err) => {
			handleErrorMessage(err);
			setFormFields((prev) => ({
				...prev,
				isApiTestDone: true,
			}));
			setIsLoading(false);
		},
	});

	const formatPayload = (payload: IAPIFormFields['apiPayload']) => {
		const tempPayload = {};
		payload.forEach((item) => {
			tempPayload[item.value] = item.testValue;
		});

		return tempPayload;
	};

	const formatCustomHeaders = (headers: IAPIFormFields['apiHeaders']) => {
		const tempHeaders: any = {};
		headers.forEach((item: any) => {
			tempHeaders[item.key] = item?.value?.trim();
		});

		return tempHeaders;
	};

	const handleTestApi = async () => {
		setIsLoading(true);
		setFormErrors({});
		await testApiMutation.mutateAsync({
			type: 'custom',
			apiDataFields: formatPayload(formFields.apiPayload),
			apiDataPassingMethod: formFields.apiPayloadMethod?.toUpperCase(),
			apiEndpoint: formFields.apiEndpoint,
			apiHeaders: formFields?.isHeaderRequired
				? formatCustomHeaders(formFields.apiHeaders)
				: {},
			apiMethod: formFields.apiMethod?.toUpperCase(),
			apiName: formFields.apiName,
		});
	};

	const handlePayloadInputTestValue = (newVal: string, index: number) => {
		// setStep(CreateTaskAPISteps.Testing);
		const tempApiPayload = [...(formFields?.apiPayload ?? [])];
		tempApiPayload[index] = {
			...tempApiPayload[index],
			testValue: newVal,
		};
		setFormFields((prev) => ({
			...prev,
			apiPayload: tempApiPayload,
			isApiTestDone: false,
		}));
	};

	return {
		handleTestApi,
		handlePayloadInputTestValue,
	};
};

export default useTestAPI;
