export interface IWidgets {
	_id: string;
	name: string;
	title?: string;
	displayLimit?: number;
	isLoyaltyWidget?: boolean;
	resourceIds: string[];
	campaign: {
		widgetId: string;
		id: string;
		name: string;
		banner?: string;
	}[];
}

export enum QuestOfTheDayStatus {
	Active = 'ACTIVE',
	Upcoming = 'UPCOMING',
}

export enum HomepageWidgetTypes {
	Trending = 'TRENDING',
	Token = 'TOKEN',
	Nft = 'NFT',
	PartialCompleted = 'PARTIAL_COMPLETED_QUEST',
	LoyaltyQuests = 'LOYALTY_QUESTS',
}

export enum HomepageWidgetSources {
	Quest = 'QUEST',
}
