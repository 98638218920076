import Hotjar from '@hotjar/browser';
import { TrackingDimensions, TrackingEvents } from '@/types/tracking.type';
import { HOTJAR_TOKEN, ENV } from '@/config';
// import mixpanel from 'mixpanel-browser';

// if (ENV === 'production') {
const siteId = HOTJAR_TOKEN;
const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);
// mixpanel.init(MIXPANEL_TOKEN, { debug: true });
// }

const getType = (value: any) => {
	if (value instanceof Date) {
		return 'date';
	}
	return typeof value;
};

export const cleanEventBody = (eventBody: any) => {
	const whitelistedParams = Object.keys(TrackingDimensions);
	const supportedTypes = ['string', 'number', 'boolean', 'date'];

	return Object.keys(eventBody).reduce((acc, key) => {
		if (
			whitelistedParams.includes(key) &&
			eventBody[key] &&
			eventBody[key] !== undefined &&
			supportedTypes.includes(getType(eventBody[key]))
		) {
			acc[key] = eventBody[key];
		}
		return acc;
	}, {});
};

export default {
	track: (event: string, eventBody?: any) => {
		console.log(`Tracking event: ${event}`, eventBody);
		// mixpanel.track(event, cleanEventBody(eventBody));
		window.dataLayer.push({ event: event, ...cleanEventBody(eventBody) });
	},
	identify: (userId: string, userProperties: any) => {
		// mixpanel.identify(userId);
		// mixpanel.people.set(cleanEventBody(userProperties));
		window.dataLayer.push({
			event: TrackingEvents.UserData,
			userId,
			...cleanEventBody(userProperties),
		});
	},
	pageView: (pageName: string, params: any) => {
		const body = cleanEventBody({ pageName, ...params });
		// mixpanel.track(TrackingEvents.PageView, body);
		window.dataLayer.push({ event: TrackingEvents.PageView, ...body });
	},
	// register: (userId: string, userProperties: any) => {
	// 	mixpanel.alias(userId);
	// 	mixpanel.people.set(cleanEventBody(userProperties));
	// },
	// reset: () => {
	// 	mixpanel.reset();
	// },
};
