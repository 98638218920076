import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';

const TooltipWrapper = ({
	tooltip,
	children,
	align = 'center',
	tooltipContentClass,
}: {
	tooltip: string | string[] | JSX.Element;
	children: React.ReactNode;
	align?: 'center' | 'start' | 'end';
	tooltipContentClass?: string;
}) => {
	if (!tooltip) return children;
	return (
		<>
			<TooltipProvider delayDuration={0}>
				<Tooltip>
					<TooltipTrigger className="">{children}</TooltipTrigger>
					<TooltipContent align={align}>
						<div
							className={cn(
								'text-sm font-normal max-w-[400px] ',
								tooltipContentClass ? tooltipContentClass : '',
							)}
						>
							{tooltip}
						</div>
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>
		</>
	);
};

export default TooltipWrapper;
