import { Button, ButtonProps } from '@/components/ui/button';
import { links } from '@/config/links';
import { queryClient } from '@/lib/react-query';
import { importToken, uploadFile } from '@/services/utility.service';
import { validateEVMAddress } from '@/utils/validation';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';

const defaultErrors = {
	name: false,
	symbol: false,
	address: false,
	logo: false,
	decimals: false,
};

export enum CustomTokenStatus {
	Pending = 'PENDING',
	Approved = 'APPROVED',
	Rejected = 'REJECTED',
}

export const useCreateToken = (setOpen: any, defaultChainId: number) => {
	const [token, setToken] = useState({
		name: '',
		symbol: '',
		address: '',
		decimals: 8,
		chain: defaultChainId ?? 137,
		logo: '',
		logoFile: null,
	});
	const [errors, setErrors] = useState(defaultErrors);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setToken({
			chain: defaultChainId,
			name: '',
			symbol: '',
			address: '',
			decimals: 8,
			logo: '',
			logoFile: null,
		});
		setErrors(defaultErrors);
	}, [defaultChainId]);

	const validateToken = () => {
		const newErors = { ...defaultErrors };
		if (!token.name) newErors.name = true;
		if (!token.symbol) newErors.symbol = true;
		if (!token.address || !validateEVMAddress(token.address))
			newErors.address = true;
		if (!token.logoFile) newErors.logo = true;
		if (!token.decimals) newErors.decimals = true;

		setErrors(errors);
		return !Object.values(errors).some((v) => v);
	};

	const saveToken = async () => {
		try {
			if (!validateToken()) return;
			setIsLoading(true);
			const logo = await uploadLogo();
			const res = await importToken({
				name: token?.name,
				symbol: token?.symbol,
				address: token?.address?.toLowerCase().trim(),
				decimals: token?.decimals,
				chainId: token?.chain,
				logo,
			});
			if (res.status === 'APPROVED') {
				toast.success('Token imported successfully');
			} else {
				toast.success(
					'Token imported successfully, but it is pending approval. Please wait for the admin to approve it.',
					{
						action: (
							<Button
								onClick={() => {
									window.open(links.docs.tokenApproval, '_blank');
								}}
							>
								Learn more
							</Button>
						),
						duration: 2000,
					},
				);
			}
			queryClient.invalidateQueries({
				queryKey: ['custom-tokens', token.chain],
			});
			setIsLoading(false);
			setOpen(false);
		} catch (err) {
			setIsLoading(false);
			console.log(err);
		}
	};

	const uploadLogo = async () => {
		return new Promise((resolve) => {
			toast.promise(
				Promise.all([
					uploadFile(token.logoFile).then((res) => {
						console.log(res);
						resolve(res);
					}),
				]),
				{
					loading: 'Uploading token logo...',
					success: 'Uploading token logo successful',
					error: 'Failed to delete action',
				},
			);
		});
	};

	return {
		token,
		setToken,
		saveToken,
		isLoading,
		errors,
	};
};
