import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { ITaskInputsProps } from './TaskInputs';
import { useEffect, useState } from 'react';
import { axios } from '@/lib/axios';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';

const TaskInputSelect = ({
	adminInput,
	taskId,
	error,
	editTaskAdminInput,
}: ITaskInputsProps) => {
	const options = adminInput.options;

	return (
		<>
			<div className="relative">
				<div className="text-sm font-medium mb-1">{adminInput.label}</div>
				{adminInput.description && (
					<div className="text-xs text-muted-foreground mb-1">
						{adminInput.description}
					</div>
				)}
				<Select
					value={adminInput.value as string}
					onValueChange={(e) => {						
						editTaskAdminInput(taskId, adminInput.key, e);
					}}
				>
					<SelectTrigger
						aria-label="Area"
						className={cn('text-sm', error && 'border-destructive')}
					>
						<div className="flex justify-between">
							{adminInput.value ? (
								<SelectValue>
									{
										options.find(
											(i) => i.value === adminInput.value,
										)?.name
									}
								</SelectValue>
							) : (
								<SelectValue placeholder={adminInput.placeholder}>
								{adminInput.placeholder}
							</SelectValue>
							)}
						</div>
					</SelectTrigger>
					<SelectContent>
						{(options || []).map((i) => (
							<SelectItem key={i.value} value={i.value}>
								{i.label}
								{i.description}
							</SelectItem>
						))}
					</SelectContent>
				</Select>
				{error && (
					<div className="text-xs text-destructive mt-1">
						This field is required
					</div>
				)}
			</div>
		</>
	);
};

export default TaskInputSelect;
