import InputText from '@/components/element/inputs/InputText';
import React from 'react';
import { IOnboardingAccount } from '../../hooks/useCommunityApproval';
import InputWrapper from '@/components/element/inputs/InputWrapper';
import { cn } from '@/lib/utils';
import InputSelect from '@/components/element/inputs/InputSelect';
import {
	goalOptions,
	profileOptions,
	sourceOptions,
} from '@/features/onboarding/types/onboarding-options';

const AccountDetails = ({
	account,
	setAccount,
	errors,
}: {
	account: IOnboardingAccount;
	setAccount: React.Dispatch<React.SetStateAction<IOnboardingAccount>>;
	errors: {
		name: boolean;
		role: boolean;
		source: boolean;
	};
}) => {
	return (
		<>
			<InputText
				label="Your Name"
				required={true}
				placeholder="Elon Musk"
				value={account.name}
				setValue={(e) =>
					setAccount({
						...account,
						name: e,
					})
				}
				errorText="Please enter your name"
				error={errors.name}
			/>
			<InputWrapper label="What describes you the best?">
				<div
					className={cn(
						'grid items-end gap-2',
						account.role && account.role === 'Others'
							? 'grid-cols-2'
							: 'grid-cols-1',
					)}
				>
					<InputSelect
						placeholder="Select your role"
						value={account.role}
						setValue={(e) =>
							setAccount({
								...account,
								role: e,
							})
						}
						errorText="Please select your role"
						error={account.role === 'Others' ? false : errors.role}
						options={profileOptions}
					/>
					{account.role === 'Others' && (
						<InputText
							placeholder="Your Role"
							value={account.otherRole}
							setValue={(e) =>
								setAccount({
									...account,
									otherRole: e,
								})
							}
							className="mt-2"
							error={errors.source}
						/>
					)}
				</div>
			</InputWrapper>
			<InputWrapper label="How did you come to know about Intract?">
				<div
					className={cn(
						'grid items-end gap-2',
						account.source && account.source === 'Others'
							? 'grid-cols-2'
							: 'grid-cols-1',
					)}
				>
					<InputSelect
						placeholder="Select Source"
						value={account.source}
						setValue={(e) =>
							setAccount({
								...account,
								source: e,
							})
						}
						errorText={'Please select the field'}
						error={account.source === 'Others' ? false : errors.source}
						options={sourceOptions}
					/>
					{account.source === 'Others' && (
						<InputText
							placeholder="Your Source"
							className="mt-2"
							value={account.otherSource}
							setValue={(e) =>
								setAccount({
									...account,
									otherSource: e,
								})
							}
							error={errors.source}
						/>
					)}
				</div>
			</InputWrapper>
		</>
	);
};

export default AccountDetails;
