import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function getTimezone() {
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const adjustedIntercomLauncherPosition = (position: string) => {
	const intercomEle = document.getElementsByClassName(
		'intercom-lightweight-app-launcher',
	);
	if (intercomEle.length > 0) {
		const ele = intercomEle[0];
		(ele as any).style.bottom = position;
		console.log('Re-adjusted Intercom launcher position');
	}
};
