import { useEffect, useState } from 'react';
import { EnterpriseApprovalStage } from '../types/enterprise.types';
import {
	getOnboardingData,
	setUpCommunityProfileAPI,
	updateAccountDetails,
	updateCommunityDetails,
} from '@/features/onboarding/services/onboarding.service';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '@/hooks/useAuth';
import { deepCopy, stripURLs, validateCompleteURL } from '@/utils/parsers';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { uploadFile } from '@/services/utility.service';
import { useGetEnterprise } from './useGetEnterprise';
import { useSearchParams } from 'react-router-dom';
import { useRouter } from '@/hooks/useRouter';
import {
	approvalDiscordCallback,
	approvalTwitterCallback,
} from '../services/verification.service';
import { queryClient } from '@/lib/react-query';
import { handleErrorMessage } from '@/utils/notifications';

export interface IOnboardingAccount {
	name: string;
	source: string;
	otherSource: string;
	role: string;
	otherRole: string;
}

export interface IOnboardingCommunity {
	logoFile: File | null | any;
	logoUrl: string;
	name: string;
	chainIds: number[];
	category: string[];
	otherCategory: string;
	goal: string[];
}

export interface IOnboardingSocials {
	website?: string;
}

type step = EnterpriseApprovalStage;

const defaultErrors = {
	account: {
		name: false,
		source: false,
		role: false,
	},
	community: {
		name: false,
		logo: false,
		chains: false,
		category: false,
		goal: false,
	},
	socials: {
		twitter: false,
		website: false,
		discord: false,
	},
};

const useCommunityApproval = ({ steps, setSteps, setOpen }) => {
	const [step, setStep] = useState<step>();
	const [isLoading, setIsLoading] = useState(false);
	const [account, setAccount] = useState<IOnboardingAccount>({
		name: '',
		source: '',
		otherSource: '',
		role: '',
		otherRole: '',
	});
	const [community, setCommunity] = useState<IOnboardingCommunity>({
		logoFile: null,
		logoUrl: '',
		name: '',
		goal: [],
		chainIds: [],
		category: [],
		otherCategory: '',
	});
	const [socials, setSocials] = useState<IOnboardingSocials>({
		website: '',
	});
	const [errors, setErrors] = useState(defaultErrors);
	const [isAuthenticating, setIsAuthenticating] = useState({
		discord: false,
		twitter: false,
	});

	const { query } = useRouter();
	const [searchParam, setSearchParam] = useSearchParams();
	const { user, refetchUser } = useAuth();
	const { enterprise, refetch: enterpriseRefetch } = useGetEnterprise();

	const { data: onboarding } = useQuery({
		queryKey: ['onboarding'],
		queryFn: () => getOnboardingData(),
	});

	useEffect(() => {
		setAccount((prev) => {
			return {
				...prev,
				name: user?.name || '',
				role: user?.onboarding?.role || '',
				source: user?.onboarding?.source || '',
			};
		});
	}, [user]);

	useEffect(() => {
		setCommunity((prev) => {
			return {
				...prev,
				logoUrl: enterprise?.logo || '',
				chainIds: enterprise?.chainIds || [],
				category: enterprise?.metadata?.category || [],
				goal: enterprise?.metadata?.goal || [],
			};
		});
	}, [enterprise]);

	useEffect(() => {
		if (!enterprise) return;
		if (query.approval === 'true') {
			setOpen(true);
			setSearchParam({});
		}
		if (enterprise?.approvalStage === EnterpriseApprovalStage.AccountDetails)
			setStep(EnterpriseApprovalStage.AccountDetails);
		else if (
			enterprise?.approvalStage === EnterpriseApprovalStage.CommunityDetails
		)
			setStep(EnterpriseApprovalStage.CommunityDetails);
		else if (
			enterprise?.approvalStage === EnterpriseApprovalStage.CommunitySocials
		)
			setStep(EnterpriseApprovalStage.CommunitySocials);
		else setStep(EnterpriseApprovalStage.Completed);
	}, [query, enterprise]);

	useEffect(() => {
		if (!enterprise) return;
		if (enterprise?.approvalData?.discord?.discordId) return;
		if (query.code && query.state === 'discord:approval') {
			setOpen(true);
			connectDiscord({
				code: query.code.toString(),
			});
		}
	}, [query, enterprise]);

	useEffect(() => {
		if (!enterprise) return;
		if (enterprise?.approvalData?.twitter?.twitterId) return;
		if (query.code && query.state?.includes('twitter:approval')) {
			setOpen(true);
			connectTwitter({
				code: query.code.toString(),
				codeVerifier: (query.state as string)?.split(':')[2],
			});
		}
	}, [query, enterprise]);

	const connectDiscord = async (data: { code: string }) => {
		try {
			if (!data) return;
			if (!data.code) return;
			if (isAuthenticating.discord) return;
			setIsAuthenticating((p) => ({ ...p, discord: true }));
			await approvalDiscordCallback({
				code: data.code,
			});
			analytics.track(TrackingEvents.ApprovalDialogActions, {
				actionType: 'discord',
			});
			queryClient.invalidateQueries({
				queryKey: ['enterprise'],
			});
			setIsAuthenticating((p) => ({ ...p, discord: false }));
		} catch (err) {
			handleErrorMessage(err);
			setIsAuthenticating((p) => ({ ...p, discord: false }));
		} finally {
			if (searchParam.has('code') || searchParam.has('state')) {
				const newParams = new URLSearchParams(location.search);
				newParams.delete('code');
				newParams.delete('state');
				setSearchParam(newParams.toString());
			}
		}
	};

	const connectTwitter = async (data: { code: string; codeVerifier: string }) => {
		try {
			if (!data) return;
			if (!data.code) return;
			if (isAuthenticating.twitter) return;
			setIsAuthenticating((p) => ({ ...p, twitter: true }));
			await approvalTwitterCallback({
				code: data.code,
				codeVerifier: data.codeVerifier,
			});
			analytics.track(TrackingEvents.ApprovalDialogActions, {
				actionType: 'twitter',
			});
			queryClient.invalidateQueries({
				queryKey: ['enterprise'],
			});
			setIsAuthenticating((p) => ({ ...p, twitter: false }));
		} catch (err) {
			handleErrorMessage(err);
			setIsAuthenticating((p) => ({ ...p, twitter: false }));
		}
	};

	const handleAccountSetup = async () => {
		try {
			setIsLoading(true);
			const account_ = {
				name: account.name,
				source:
					account.source === 'Others'
						? account.otherSource
						: account.source,
				role: account.role === 'Others' ? account.otherRole : account.role,
			};
			await updateAccountDetails(account_);
			await enterpriseRefetch();
			await refetchUser();
			analytics.track(TrackingEvents.OnboardingProfileUpdated, {});
			setStep(EnterpriseApprovalStage.CommunityDetails);
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			handleErrorMessage(error);
		}
	};

	const handleCommunitySetup = async () => {
		try {
			setIsLoading(true);
			const data = {
				chainIds: community.chainIds,
				category: community.category?.includes('Others')
					? [...community.category, community.otherCategory]
					: community.category,
				logo: '',
				goal: community.goal,
			};
			if (
				community.logoFile &&
				(community.logoUrl.includes('blob:') || !community.logoUrl)
			) {
				const bannerImage = await uploadFile(community.logoFile);
				data.logo = bannerImage;
				setCommunity((prev) => {
					return {
						...prev,
						logoUrl: bannerImage,
					};
				});
			} else {
				data.logo = community.logoUrl;
			}
			await updateCommunityDetails(data);
			await enterpriseRefetch();
			analytics.track(TrackingEvents.OnboardingCommunityUpdated, {});
			setStep(EnterpriseApprovalStage.CommunitySocials);
		} catch (error) {
			handleErrorMessage(error);
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleComplete = async () => {
		try {
			setIsLoading(true);
			const completeURL = validateCompleteURL(socials.website);

			const strippedURL = stripURLs({
				website: completeURL,
			});
			setSocials((prev) => {
				return {
					...prev,
					website: strippedURL.website,
				};
			});
			await updateCommunityDetails({
				socials: {
					website: completeURL,
				},
			});
			analytics.track(TrackingEvents.OnboardingSocialsUpdated, {});

			await enterpriseRefetch();
			setOpen(false);
			setStep(EnterpriseApprovalStage.Completed);
		} catch (error) {
			console.log(error);
			handleErrorMessage(error);
		} finally {
			setIsLoading(false);
		}
	};

	const validateURL = (url: string) => {
		const linkValidationRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/\S*)?$/;
		return linkValidationRegex.test(url);
	};

	const handleNext = async () => {
		if (!validate(step)) return;

		// const updatedSteps = steps.map((item) =>
		// 	item.key === step ? { ...item, complete: true } : item,
		// );

		// setSteps(updatedSteps);

		if (step === EnterpriseApprovalStage.AccountDetails) {
			await handleAccountSetup();
		} else if (step === EnterpriseApprovalStage.CommunityDetails) {
			await handleCommunitySetup();
		} else if (step === EnterpriseApprovalStage.CommunitySocials) {
			await handleComplete();
		} else {
			setOpen(false);
		}
		// queryClient.invalidateQueries({
		// 	queryKey: ['onboarding'],
		// });
	};

	useEffect(() => {
		setErrors(deepCopy(defaultErrors));
	}, [account, community, socials]);

	const validate = (step: step) => {
		let isValidated = true;
		if (step === EnterpriseApprovalStage.AccountDetails) {
			const newErrors = { ...errors };
			if (!account.name) newErrors.account.name = true;

			setErrors(newErrors);
			isValidated = !Object.values(newErrors.account).some((e) => e);
		}
		if (step === EnterpriseApprovalStage.CommunityDetails) {
			const newErrors = { ...errors };
			if (!community.logoFile && !onboarding?.community?.logoUrl)
				newErrors.community.logo = true;
			if (!community.category.length) newErrors.community.category = true;
			if (!community.chainIds || community.chainIds.length <= 0)
				newErrors.community.chains = true;
			if (!community.goal || community.goal.length <= 0)
				newErrors.community.goal = true;

			setErrors(newErrors);
			isValidated = !Object.values(newErrors.community).some((e) => e);
		}
		if (step === EnterpriseApprovalStage.CommunitySocials) {
			const newErrors = { ...errors };

			if (!socials.website) newErrors.socials.website = true;
			if (socials.website && !validateURL(socials.website))
				newErrors.socials.website = true;

			if (
				!enterprise?.approvalData?.twitter ||
				!enterprise?.approvalData?.twitter?.twitterId
			)
				newErrors.socials.twitter = true;

			setErrors(newErrors);
			isValidated = !Object.values(newErrors.socials).some((e) => e);
		}
		return isValidated;
	};
	const handleBack = async () => {
		if (step === EnterpriseApprovalStage.CommunityDetails) {
			setStep(EnterpriseApprovalStage.AccountDetails);
		} else if (step === EnterpriseApprovalStage.CommunitySocials) {
			setStep(EnterpriseApprovalStage.CommunityDetails);
		} else if (step === EnterpriseApprovalStage.Completed) {
			setStep(EnterpriseApprovalStage.CommunitySocials);
		}
	};
	return {
		step,
		setStep,
		isLoading,
		setIsLoading,
		account,
		setAccount,
		community,
		setCommunity,
		handleNext,
		handleBack,
		errors,
		setErrors,
		socials,
		setSocials,
		isAuthenticating,
		setIsAuthenticating,
	};
};

export default useCommunityApproval;
