/* eslint-disable no-mixed-spaces-and-tabs */
import { Button } from '@/components/ui/button';
import { useEffect, useState } from 'react';
import { Checkbox } from '@/components/ui/checkbox';
import { PayloadItem } from './PayloadItem';
import { useConnectAPI } from '../hooks/useConnectAPI';
import { CustomHeaders } from './CustomHeaders';
import { ApiDataEnums, ApiValueKeys } from '../types/task-apis.enum';

export const PayloadDetails = () => {
	const [items, setItems] = useState(1);
	const [headers, setHeaders] = useState(1);

	const { formFields, setFormFields, formErrors, setFormErrors } = useConnectAPI();

	const handleCustomHeaders = (checked: boolean) => {
		if (headers === 0 && checked) setHeaders(1);
		setFormFields((prev) => ({
			...prev,
			isHeaderRequired: !!checked,
		}));
		setFormErrors((prev) => ({
			...prev,
			apiHeaders: '',
		}));
	};
	const addPayloadItem = () => {
		if (items >= 3) return;

		setFormFields((prev) => {
			const existingKeys = new Set(prev.apiPayload.map((item) => item.value));
			const newKey = ApiValueKeys.find(
				(apiKey) => !existingKeys.has(apiKey.value),
			);

			if (newKey) {
				const tempApiPayload = [
					...prev.apiPayload,
					{
						key: newKey.value,
						value: newKey.value,
						testValue: '',
					},
				];

				// Also update the item count in a single state update
				setItems((prevItems) => prevItems + 1);

				return {
					...prev,
					apiPayload: tempApiPayload,
				};
			}

			return prev;
		});
	};

	const addCustomHeader = () => {
		if (headers >= 7) return;
		setHeaders((prevItems: number) => {
			const tempApiHeaders = [...formFields.apiHeaders];
			tempApiHeaders.push({ key: 'Authorization', value: '' });
			setFormFields((prev: any) => ({
				...prev,
				apiHeaders: tempApiHeaders,
			}));
			return prevItems + 1;
		});
	};
	useEffect(() => {
		if (formFields?.apiHeaders?.length > 0) {
			setHeaders(formFields?.apiHeaders?.length);
		}
	}, [formFields?.apiHeaders?.length]);
	useEffect(() => {
		if (formFields?.apiPayload?.length > 0) {
			setItems(formFields?.apiPayload?.length);
		}
	}, [formFields?.apiPayload?.length]);
	return (
		<div>
			<div>
				<div className="text-sm font-medium">Payload</div>
				<div className="overflow-y-scroll max-h-[9rem] mb-1">
					{formFields?.apiPayload?.length > 0 ? (
						formFields?.apiPayload?.map((_item: any, index: number) => (
							<div key={_item.key}>
								{/* TODO: create a unique id for each payload item */}
								<PayloadItem setItems={setItems} index={index} />
								{formErrors[formFields?.apiPayload?.[index]?.key] ? (
									<p className="mb-0 pt-1 text-right text-xs text-destructive">
										{
											formErrors[
												formFields?.apiPayload?.[index]?.key
											]
										}
									</p>
								) : null}
							</div>
						))
					) : (
						<></>
					)}
				</div>
				{items < 3 ? (
					<div className="flex justify-end gap-1">
						<Button
							className="mt-3 "
							variant="ghost"
							size="sm"
							onClick={() => addPayloadItem()}
						>
							+ Add Payload
						</Button>
					</div>
				) : null}
			</div>
			<div>
				<div className="flex flex-row items-start space-x-3 mt-10">
					<Checkbox
						className="mt-1"
						checked={formFields?.isHeaderRequired}
						onCheckedChange={(checked) =>
							handleCustomHeaders(checked as boolean)
						}
					/>
					<div className="space-y-1 leading-none">
						<div className="text-sm font-medium">
							Does the API requires custom headers?
						</div>
						<div className="text-xs">
							You can use headers for additional authentication or to
							access private APIs.
						</div>
					</div>
				</div>
				{formFields?.isHeaderRequired ? (
					<div className="mb-5">
						<div className="">
							{formFields?.apiHeaders?.length > 0 ? (
								formFields?.apiHeaders?.map(
									(_item: any, index: number) => (
										<div className="pl-7" key={index}>
											<CustomHeaders
												items={headers}
												setItems={setHeaders}
												index={index}
											/>
											{formErrors[
												formFields?.apiHeaders?.[index]?.key
											] ? (
												<p className="mb-0 pt-1 text-right text-xs text-destructive">
													{
														formErrors[
															formFields?.apiHeaders?.[
																index
															]?.key
														]
													}
												</p>
											) : null}
										</div>
									),
								)
							) : (
								<></>
							)}
						</div>
						{headers < 7 ? (
							<div className="flex justify-end mt-3 pt-1">
								<Button
									variant="ghost"
									size="sm"
									className="flex items-center gap-1"
									onClick={() => addCustomHeader()}
								>
									+ Add Header
								</Button>
							</div>
						) : null}
					</div>
				) : null}
				<div className="flex flex-row items-start space-x-3 mt-5">
					<Checkbox
						className="mt-1"
						checked={
							formFields?.isMetricBasedTaskEnabled &&
							!formFields?.isRecurringTasksEnabled
						}
						onCheckedChange={(checked) =>
							setFormFields((prev: any) => ({
								...prev,
								isMetricBasedTaskEnabled: !!checked,
								isRecurringTasksEnabled: false,
							}))
						}
					/>
					<div className="space-y-1 leading-none">
						<div className="text-sm font-medium">
							Does your API returns value of the action?
						</div>
						<div className="text-xs">
							You can configure dynamic XPs for tasks based on impact
							of the user action. For this API needs to send{' '}
							<code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono  font-semibold">
								value
							</code>{' '}
							.
						</div>
					</div>
				</div>
				<div className="flex flex-row items-start space-x-3 mt-5">
					<Checkbox
						className="mt-1"
						checked={
							formFields?.isRecurringTasksEnabled &&
							!formFields?.isMetricBasedTaskEnabled
						}
						onCheckedChange={(checked) =>
							setFormFields((prev: any) => ({
								...prev,
								isRecurringTasksEnabled: !!checked,
								isMetricBasedTaskEnabled: false,
							}))
						}
					/>
					<div className="space-y-1 leading-none">
						<div className="text-sm font-medium">
							Does your API supports verification for recurring tasks?
						</div>

						<div className="text-xs">
							For recurring tasks, we will send{' '}
							<code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono  font-semibold">
								startTimestamp
							</code>{' '}
							and{' '}
							<code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono  font-semibold">
								endTimestamp
							</code>{' '}
							to verify user action during the period.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
