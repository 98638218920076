import { useAuth } from '@/hooks/useAuth';
import { axios } from '@/lib/axios';
import {
	getSupportedTokens,
	getSupportedTokensForChain,
} from '@/services/utility.service';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

export interface ITokens {
	_id: string;
	address: string;
	chainId: number;
	decimals: string;
	logo: string;
	name: string;
	symbol: string;
	enterpriseId: {
		name: string;
		_id: string;
	};
}
export const useGetAllTokensForEnterprise = (chainId?: number) => {
	const { user } = useAuth();

	const [search, setSearch] = useState('');

	const { data: enterpriseTokens, isLoading: isEnterpriseTokensLoading } =
		useQuery<ITokens[]>({
			enabled: !!user?._id && chainId > 0,
			queryKey: ['custom-tokens', chainId],
			refetchOnWindowFocus: false,
			queryFn: () => getSupportedTokensForChain(chainId),
		});

	const { data: allTokens, isLoading: isLoading } = useQuery<ITokens[]>({
		enabled: !!user?._id,
		queryKey: ['all-tokens'],
		refetchOnWindowFocus: false,
		queryFn: () => getSupportedTokens(),
	});

	const filteredTokens = useMemo(() => {
		if (!allTokens) return [];
		return allTokens.filter((i) => {
			if (search && !i.name.toLowerCase().includes(search.toLowerCase()))
				return false;
			return true;
		});
	}, [allTokens, search]);

	return {
		tokens: enterpriseTokens ?? [],
		allTokens: filteredTokens ?? [],
		isAllTokensLoading: isLoading,
		isLoading: isEnterpriseTokensLoading,
		search,
		setSearch,
	};
};
