import { Separator } from '@/components/ui/separator';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useCommunityHub } from '../hooks/useCommunityHub';
import BannerWithLogo from './BannerWithLogo';
import { QuillEditor } from '@/components/element/rich-text-editor/QuillEditor';
import { SelectNetworksTag } from '@/components/element/inputs/SelectNetworksTab';
import InputMultiSelect from '@/components/element/inputs/InputMultiSelect';
import InputText from '@/components/element/inputs/InputText';

const CommunityHubOverview = () => {
	const {
		formFields,
		setFormFields,
		formErrors,
		logoRef,
		bannerRef,
		updateCommunityProfile,
		loading,
		updateLogo,
	} = useCommunityHub();

	return (
		<div className="space-y-6 mt-5">
			<div className="flex  items-top">
				<div className="w-full">
					<BannerWithLogo
						bannerRef={bannerRef}
						file={formFields.banner}
						setFile={(file: File, fileLink: string) => {
							setFormFields((prev: any) => ({
								...prev,
								banner: file,
								bannerUrl: fileLink,
							}));
						}}
						fileLink={formFields.bannerUrl}
						description="Upload your logo to be displayed on your community hub."
						cta="Upload Banner"
						aspectRatio={1184 / 200}
						className="mb-4"
						customHeight="max-h-[17.8rem]"
						updateLogo={updateLogo}
						logoRef={logoRef}
						logoURL={formFields.logo || formFields.logoUrl}
						setBannerPosition={({ x, y }) => {
							setFormFields((prev: any) => ({
								...prev,
								bannerPosition: { x, y },
							}));
						}}
						bannerPos={formFields.bannerPosition}
					/>
				</div>
			</div>
			<Separator className="my-4" />
			<div className="flex">
				<div className="w-1/3">
					<div className="text-sm font-medium mb-2">Project name</div>
				</div>
				<div>
					<Input
						placeholder="Project name"
						className={`w-[26rem] ${formErrors['name'] ? 'border-destructive' : ''}`}
						value={formFields?.name}
						onChange={(e) =>
							setFormFields((prev: any) => ({
								...prev,
								name: e.target.value,
							}))
						}
					/>
					{formErrors['name'] && (
						<p className={'text-xs  text-destructive mt-1'}>
							{formErrors['name']}
						</p>
					)}
				</div>
			</div>
			<div className="flex">
				<div className="w-1/3">
					<div className="text-sm font-medium mb-2">
						Project description
					</div>
				</div>
				<div>
					<QuillEditor
						value={formFields?.description}
						setValue={(e) =>
							setFormFields((prev: any) => ({
								...prev,
								description: e,
							}))
						}
						placeholder="Project description"
						className="min-w-[26rem] max-w-[26rem]"
					/>
				</div>
			</div>
			<div className="flex">
				<div className="w-1/3">
					<div className="text-sm font-medium mb-2">Primary Chain</div>
				</div>
				<div className="w-[26rem]">
					<SelectNetworksTag
						placeholder="Select a chain"
						value={[
							...formFields.chainIds,
							...formFields.customChainIds,
						]}
						setValue={({ chainIds, customChainIds }) => {
							setFormFields((prev) => ({
								...prev,
								chainIds,
								customChainIds,
							}));
						}}
						error={formErrors?.chainId}
						errorMsg={formErrors?.chainId}
						isMulti
						fetchEnterpriseChains
					/>
				</div>
			</div>
			<div className="flex">
				<div className="w-1/3">
					<div className="text-sm font-medium mb-2">Project Category</div>
				</div>
				<div className="flex space-x-3 max-w-[26rem]">
					<InputMultiSelect
						value={formFields.category}
						setValue={(e) =>
							setFormFields((p) => ({ ...p, category: e }))
						}
						options={categories}
						required={true}
						placeholder="Select Category"
						error={false}
						className="w-[26rem]"
					/>
					{formFields.category?.includes('Others') && (
						<InputText
							required={true}
							placeholder="Others"
							value={formFields.otherCategory}
							setValue={(e) =>
								setFormFields((p) => ({
									...p,
									otherCategory: e,
								}))
							}
						/>
					)}
				</div>
			</div>

			<Separator className="my-4" />
			<Button
				type="submit"
				onClick={updateCommunityProfile}
				disabled={loading}
			>
				{loading ? (
					<i className="bi bi-arrow-clockwise animate-spin mr-1 text-sm"></i>
				) : null}
				Update profile
			</Button>
		</div>
	);
};

export default CommunityHubOverview;

const categories_ = [
	'DeFi',
	'NFT',
	'Memecoins',
	'Gaming',
	'Dex',
	'Staking',
	'Bridge',
	'Collectibles',
	'DID',
	'Infrastructure',
	'Metaverse',
	'DAO',
	'Social',
	'DeSci',
	'Others',
];
const categories: {
	label: string;
	value: string;
}[] = categories_.map((category) => ({
	label: category,
	value: category,
}));
