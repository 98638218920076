import DropzoneWithReposition from '@/components/element/dropzone/DropzoneWithReposition';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogDescription,
	DialogTitle,
	DialogFooter,
} from '@/components/ui/dialog';
import { Dispatch, SetStateAction } from 'react';
import AddBannerDetails from './AddBannerDetails';
import InputTextArea from '@/components/element/inputs/InputTextArea';
import { Button } from '@/components/ui/button';
import InputDuration from '@/components/element/inputs/input-duration/InputDuration';
import { IBannerCreate } from '@/features/admin/hooks/admin-cms-banners/useBannerCreate';

const CreateBanner = ({
	open,
	setOpen,
	formFields,
	setFormFields,
	formErrors,
	handleSubmit,
	resetForm,
	isPending,
}: {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	formFields: IBannerCreate;
	setFormFields: Dispatch<SetStateAction<IBannerCreate>>;
	formErrors: any;
	handleSubmit: () => void;
	resetForm: () => void;
	isPending: boolean;
}) => {
	const editDetails = (
		key: string,
		value: string | Date | boolean | { x: number; y: number },
	) => {
		setFormFields((prev) => ({ ...prev, [key]: value }));
	};
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[680px] px-0 pb-0 overflow-visible">
				<DialogHeader className="border-b pb-3 px-5">
					<DialogTitle>Create a new banner</DialogTitle>
					<DialogDescription>
						Configure the banner from a quest or start with a blank
						canvas.
					</DialogDescription>
				</DialogHeader>
				<div className="flex flex-col justify-between items-center px-5">
					<div className="grid grid-cols-2 gap-10 w-full mt-4">
						<AddBannerDetails
							formFields={formFields}
							setFormFields={setFormFields}
							formErrors={formErrors}
						/>
						<DropzoneWithReposition
							file={formFields.imageFile}
							required
							clearSelection={() => {
								setFormFields({
									...formFields,
									banner: '',
									imageFile: null,
								});
							}}
							setFile={(file: File, fileLink: string) => {
								setFormFields({
									...formFields,
									imageFile: file,
									banner: fileLink,
								});
							}}
							fileLink={formFields.banner}
							allowedFiles={['images']}
							description="Recommended size: 400x400px"
							label="Upload Banner Image"
							cta="Upload the image"
							aspectRatio={1 / 1}
							className="w-full"
							customHeight="!max-h-[9rem]"
							dropzoneClass="bg-muted !h-[9rem] mt-4"
							error={formErrors['banner']}
							errorText={formErrors['banner']}
							onImageReposition={({ x, y }) => {
								setFormFields({
									...formFields,
									imagePosition: { x, y },
								});
							}}
							lockBodyScroll={false}
							showPlaceHolderImage
						/>
					</div>
					<div className="w-full mt-4 mb-4">
						<InputDuration
							className=""
							startDate={formFields?.startDate}
							endDate={formFields?.endDate}
							setStartDate={(date) => editDetails('startDate', date)}
							setEndDate={(date) => editDetails('endDate', date)}
							startImmediately={formFields?.startImmediately}
							setStartImmediately={(e) =>
								editDetails('startImmediately', e)
							}
							timezone={formFields?.timezone}
							setTimezone={(e) => editDetails('timezone', e)}
						/>
					</div>
				</div>
				<DialogFooter className="flex justify-between border-t px-5 pt-3 pb-3 w-full sm:justify-between">
					<div></div>
					<div className="space-x-2 flex items-center ">
						<Button
							variant="outline"
							onClick={() => {
								resetForm();
								setOpen(false);
							}}
						>
							Cancel
						</Button>
						<Button onClick={() => handleSubmit()} disabled={isPending}>
							{isPending ? (
								<i className="bi-arrow-clockwise animate-spin me-2"></i>
							) : null}
							<span>Update</span>
						</Button>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default CreateBanner;
