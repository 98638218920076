import { Status } from '@/features/campaigns/types';

export enum EnterpriseApprovalStatus {
	Pending = 'PENDING',
	Approved = 'APPROVED',
	Rejected = 'REJECTED',
}

export enum EnterpriseOnboardingStage {
	SignedUp = 'SIGNED_UP',
	AccountDetails = 'ACCOUNT_DETAILS',
	Community = 'COMMUNITY',
	EmailConfirmationPending = 'EMAIL_CONFIRMATION',
	Completed = 'COMPLETED',
}

export interface IEnterpriseApprovalData {
	announcementScreenshot: string;
	discord: {
		discordId: string;
		username?: string;
		roles?: string[];
		avatar?: string;
		discriminator?: string;
		joinedAt?: Date;
		auth?: {
			accessToken?: string;
			refreshToken?: string;
			lastUpdatedAt?: string;
		};
		guilds?: {
			guildId: string;
			name?: string;
			owner?: boolean;
			permissions?: string;
		}[];
		isGuildMember?: boolean;
	};
	twitter: {
		twitterId: string;
		followersCount?: number;
		username?: string;
		auth?: {
			accessToken?: string;
			refreshToken?: string;
			lastUpdatedAt?: Date;
			lastClientId?: string;
		};
		metadata?: {
			name?: string;
			description?: string;
			profile_image_url?: string;
			verified?: boolean;
			public_metrics?: {
				followers_count?: number;
				following_count?: number;
				tweet_count?: number;
				listed_count?: number;
			};
		};
	};
}

export interface IEnterprise {
	name?: string;
	description?: string;
	slug?: string;
	primaryChainId?: number;
	primaryGuildId?: string;
	primaryTwitterId?: string;
	logo?: string;
	banner?: string;
	ownerId: string;
	members: string[];
	metadata: {
		[key: string]: any;
	};
	followerCount?: number;
	socials: {
		twitter?: string;
		discord?: string;
		website?: string;
		telegram?: string;
		guildXyz?: string;
	};
	customQuestUrl: string;
	defaultSubdomain: string;
	isDeleted: boolean;
	isChainNotSupported: boolean;
	requestedChain: string;
	trackedTokens: string[];
	pricingPlan: string;
	timezone: string;
	trending?: boolean;
	locked?: boolean;
	sortIndex?: {
		trending?: number;
	};
	isVerified: boolean;
	isApproved?: boolean;
	removeDisclaimer?: true;
	allowExternalTokenReward?: boolean;
	showLoyaltyWelcomePopup?: boolean;
	chainIds: number[];
	bannerPosition: {
		x: number;
		y: number;
	};
	onboardingStatus?: {
		community?: boolean;
		socials?: boolean;
		quest?: boolean;
	};
	approvalStatus?: EnterpriseApprovalStatus;
	approvalData?: IEnterpriseApprovalData;
	onboardingStage: EnterpriseOnboardingStage;
	credits: {
		remaining: 10000;
		total: 10000;
	};
	approvalStage: EnterpriseApprovalStage;
	completedUsersCount: number;
	status: Status;
}

export enum EnterpriseApprovalStage {
	AccountDetails = 'ACCOUNT_DETAILS',
	CommunityDetails = 'COMMUNITY_DETAILS',
	CommunitySocials = 'COMMUNITY_SOCIALS',
	Completed = 'COMPLETED',
}

export enum EnterpriseTokenStatus {
	Listed = 'LISTED',
	OfficialAirdropAnnounced = 'OFFICIAL_AIRDROP_ANNOUNCED',
	ICOAnnounced = 'ICO_ANNOUNCED',
	TGEComing = 'TGE_COMING',
	Unknown = 'UNKNOWN',
}

export enum EnterpriseLeadType {
	Plg = 'PLG',
	Enterprise = 'ENTERPRISE',
}
