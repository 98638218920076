import Sections from './CampaignCreateSection';
import Navigation from './CampaignCreateNavigation';
import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';
import FullScreenCreateLayout from '@/components/layout/full-screen-create/FullScreenCreateLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { CampaignRewardCategory, Status } from '@/features/campaigns/types';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import CampaignLaunchDialog from '../launch/CampaignLaunchDialog';
import EnterpriseApprovalProcess from '@/features/dashboard/components/verify/EnterpriseApprovalProcess';
import { useState } from 'react';
import { useGetEnterprise } from '@/features/dashboard/hooks/useGetEnterprise';

const CampaignCreateLayout = ({ children, section }: any) => {
	const { id } = useParams();
	const {
		details,
		isInitalising,
		showLaunch,
		setShowLaunch,
		rewards,
		setDetails,
		isUpdate,
		campaign,
	} = useCreateCampaign();
	const navigate = useNavigate();

	const { enterprise } = useGetEnterprise();

	const [showApprovalCompletionDialog, setShowApprovalCompletionDialog] =
		useState(false);

	if (isInitalising) {
		return <FullPageLoading />;
	}
	return (
		<FullScreenCreateLayout showGrid={section === 'tasks'}>
			<FullScreenCreateLayout.Header>
				<FullScreenCreateLayout.Title
					name={details?.name}
					placeholder={'Untitled Quest'}
					status={Status.Draft}
					lastSaved={
						isUpdate && campaign?.updatedAt
							? new Date(campaign.updatedAt)
							: null
					}
					onFeedback={() => {
						window.open('https://forms.gle/SL6A1tr72ekPjvrY6', '_blank');
					}}
					setName={(e) => {
						setDetails((prev) => ({ ...prev, name: e }));
					}}
					onBack={() => navigate('/app/campaign/quests')}
				/>
				<FullScreenCreateLayout.Sections>
					<Sections />
				</FullScreenCreateLayout.Sections>
			</FullScreenCreateLayout.Header>
			<FullScreenCreateLayout.Content>
				{children}
			</FullScreenCreateLayout.Content>
			<FullScreenCreateLayout.Navigation>
				<Navigation
					enterprise={enterprise}
					setShowApprovalCompletionDialog={setShowApprovalCompletionDialog}
				/>
			</FullScreenCreateLayout.Navigation>
			<CampaignLaunchDialog
				open={showLaunch}
				setOpen={setShowLaunch}
				campaignId={id}
				tokenReward={
					rewards.find(
						(reward) => reward.category === CampaignRewardCategory.Token,
					)?.isActive
						? rewards.find(
								(reward) =>
									reward.category === CampaignRewardCategory.Token,
							)
						: undefined
				}
				startImmediately={details.startImmediately}
			/>
			<EnterpriseApprovalProcess
				open={showApprovalCompletionDialog}
				setOpen={setShowApprovalCompletionDialog}
				enterpriseDetails={enterprise}
			/>
		</FullScreenCreateLayout>
	);
};

export default CampaignCreateLayout;
