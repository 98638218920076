import { Dispatch, SetStateAction } from 'react';
import { IOnboardingSocials } from '../../hooks/useCommunityApproval';
import InputText from '@/components/element/inputs/InputText';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import Spinner from '@/components/element/loading/Spinner';
import { API_URL } from '@/config';
import { cn } from '@/lib/utils';

const SocialsSetup = ({
	socials,
	setSocials,
	errors,
	enterprise,
	isAuthenticating,
	setIsAuthenticating,
}: {
	socials: IOnboardingSocials;
	setSocials: Dispatch<SetStateAction<IOnboardingSocials>>;
	errors: {
		website: boolean;
		twitter: boolean;
		discord: boolean;
	};
	enterprise: any;
	isAuthenticating: {
		twitter: boolean;
		discord: boolean;
	};
	setIsAuthenticating: Dispatch<
		SetStateAction<{
			twitter: boolean;
			discord: boolean;
		}>
	>;
}) => {
	return (
		<>
			<div className="flex flex-col space-y-6 mt-8">
				<div className="flex items-center justify-between bg-white ">
					<div className="flex items-center">
						<div className="h-10 w-10 border rounded-full flex items-center justify-center me-3">
							<i className="bi bi-twitter text-xl text-blue-500"></i>
						</div>
						<div className="">
							<div className="text-sm font-medium">
								Connect Project Twitter
								<span className="text-destructive ms-1">*</span>
							</div>
							<div className="text-xs text-muted-foreground">
								Connect your project's Twitter to our platform.
							</div>
						</div>
					</div>
					{enterprise?.approvalData?.twitter?.twitterId ? (
						<Badge variant="secondary">
							<i className="bi-check-circle-fill text-green-500 me-1 py-1"></i>
							Completed
						</Badge>
					) : (
						<Button
							className={cn('px-6')}
							size="sm"
							variant="secondary"
							onClick={() => {
								setIsAuthenticating((p) => ({
									...p,
									twitter: true,
								}));
								window.location.href = `${API_URL}/api/v1/enterprise/approval/twitter`;
							}}
							disabled={isAuthenticating.twitter}
						>
							{isAuthenticating.twitter && (
								<Spinner className="me-2" />
							)}
							<span>Connect</span>
						</Button>
					)}
				</div>
				{errors.twitter && (
					<p className={'text-xs  text-destructive text-right !mt-4'}>
						{"Please connect your project's Twitter account first"}
					</p>
				)}
				<div className="flex items-center justify-between bg-white">
					<div className="flex items-center">
						<div className="h-10 w-10 border rounded-full flex items-center justify-center me-3">
							<i className="bi bi-discord text-xl text-blue-600"></i>
						</div>
						<div className="">
							<div className="text-sm font-medium">
								Connect Project Discord
							</div>
							<div className="text-xs text-muted-foreground">
								Connect your project's Discord server to our
								platform.
							</div>
						</div>
					</div>
					{enterprise?.approvalData?.discord?.discordId ? (
						<Badge variant="secondary">
							<i className="bi-check-circle-fill text-green-500 me-1 py-1"></i>
							Completed
						</Badge>
					) : (
						<Button
							className="px-6"
							size="sm"
							variant="secondary"
							onClick={() => {
								setIsAuthenticating((p) => ({
									...p,
									discord: true,
								}));
								window.location.href = `${API_URL}/api/v1/enterprise/approval/discord`;
							}}
							disabled={isAuthenticating.discord}
						>
							{isAuthenticating.discord && (
								<Spinner className="me-2" />
							)}
							<span>Connect</span>
						</Button>
					)}
				</div>
			</div>

			<InputText
				label="Project Website"
				required={true}
				placeholder="intract.io"
				value={socials.website}
				setValue={(e) =>
					setSocials({
						...socials,
						website: e,
					})
				}
				prepend={
					<div className="ps-3 pe-2 flex items-center text-xs">
						<i className="bi bi-globe text-red-400 pe-2"></i>
						<span className="text-muted-foreground font-medium">
							https://
						</span>
					</div>
				}
				errorText="Please enter your valid website"
				error={errors.website}
			/>
		</>
	);
};

export default SocialsSetup;
