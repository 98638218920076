import { Button } from '@/components/ui/button';
import { useState } from 'react';
import CampaignsTable from '../components/campaigns/CampaignsTable';
import CampaignsMetrics from '../components/campaigns/CampaignsMetrics';
import { useCampaigns } from '../hooks/report/useCampaigns';
import LaunchNewDialog from '../components/campaigns/LaunchNewCampaign';
import NoDataCard from '@/components/element/cards/NoDataCard';
import { links } from '@/config/links';
import CmapaignSettingsDialog from '../components/campaigns/settings/CampaignSettingsDialog';
import PageHeader from '@/components/layout/PageHeader';
import { TrackingEvents } from '@/types/tracking.type';
import analytics from '@/lib/analytics';
import { useRouter } from '@/hooks/useRouter';

const CampaignTablePage = () => {
	const [showLaunchDialog, setShowLaunchDialog] = useState(false);
	const [showSettings, setShowSettings] = useState(false);
	const {
		campaigns,
		isLoading,
		filters,
		setFilters,
		setSearch,
		search,
		metrics,
		totalCampaigns,
		isTotalUsersLoading,
		templates,
	} = useCampaigns();

	const { navigate } = useRouter();

	return (
		<div>
			<div>
				<PageHeader
					title="Quests"
					description="Create quests to engage your users and drive growth."
					learn={totalCampaigns === 0 && links.docs.campaigns}
					btnLabel="Create Quest"
					icon="plus-circle-fill"
					btnOnClick={() => {
						// setShowLaunchDialog(true);
						navigate('/app/campaign/quests/new');
					}}
				>
					<Button
						className="me-2"
						variant="outline"
						onClick={() => setShowSettings(true)}
					>
						<i className="bi-gear me-2"></i>
						Settings
					</Button>
				</PageHeader>
				{totalCampaigns === 0 ? (
					<NoDataCard
						title="Launch your First Quest"
						description="Ready to engage and grow your audience? Create your first quest and set tasks that attract and retain users."
						btnLabel="Create Quest"
						btnOnClick={() => {
							// setShowLaunchDialog(true);
							navigate('/app/campaign/quests/new');
						}}
						className="mt-10"
						image={
							'https://framerusercontent.com/images/FDHo3WcPW7a3wsMsA8ocKmfoH3s.png'
						}
					/>
				) : (
					<div className="mt-8 d-block ">
						<CampaignsMetrics
							metrics={metrics}
							isLoading={isLoading}
							isTotalUsersLoading={isTotalUsersLoading}
						/>
						<CampaignsTable
							data={campaigns}
							filters={filters}
							setFilters={setFilters}
							setSearch={setSearch}
							search={search}
							isLoading={isLoading}
						/>
					</div>
				)}
			</div>
			<LaunchNewDialog
				open={showLaunchDialog}
				setOpen={setShowLaunchDialog}
				templates={templates || []}
			/>
			<CmapaignSettingsDialog open={showSettings} setOpen={setShowSettings} />
		</div>
	);
};

export default CampaignTablePage;
