import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandItem,
	CommandList,
	CommandSeparator,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';
import { cn } from '@/lib/utils';
import { CheckIcon } from '@radix-ui/react-icons';
import { useEffect, useMemo, useState } from 'react';
import ChainBadge from '../badges/ChainBadge';
import { additionalChainsNotInDB } from '@/utils/chains/additionalChainsNotInDB';
import { CommandInput } from 'cmdk';
import { Separator } from '@/components/plate-ui/separator';

interface Network {
	name: string;
	value: number;
	chainId: number;
	img: string;
	type: string;
}

export function SelectNetworksTag({
	value,
	setValue,
	error,
	errorMsg,
	allowedChainIds,
	placeholder,
	isMulti,
	defaultAll = true,
	withoutPortal,
	disabled,
	showNonEVM,
	fetchEnterpriseChains,
}: {
	value: number[];
	isMulti?: boolean;
	setValue: (updatedValues: {
		chainIds: number[];
		customChainIds: number[];
	}) => void;
	allowedChainIds?: number[];
	error?: boolean | string;
	errorMsg?: string;
	placeholder?: string;
	defaultAll?: boolean;
	withoutPortal?: boolean;
	disabled?: boolean;
	showNonEVM?: boolean;
	fetchEnterpriseChains?: boolean;
}) {
	const { chains } = useGetSupportedChains({
		showAdditionalChains: true,
		fetchEnterpriseChains: fetchEnterpriseChains,
	});

	function mergeChains(originalChains: any[], newChains: any[]): any[] {
		if (!originalChains) return newChains;
		const existingChainNames = new Set(
			originalChains.map((chain) => chain.chainName),
		);
		const filteredNewChains = newChains.filter(
			(chain) => !existingChainNames?.has(chain.chainName),
		);
		return [...originalChains, ...filteredNewChains];
	}

	const finalChainsArray = mergeChains(chains, additionalChainsNotInDB);

	const networks = useMemo(() => {
		if (!finalChainsArray) return [];
		return [
			{
				title: 'Mainnet',
				networks: finalChainsArray
					.filter((chain) => !chain.isTestnet && !chain.isCustom)
					?.map((i) => ({
						name: i.chainName,
						value: Number(i.chainId),
						chainId: Number(i.chainId),
						img: i.chainLogo,
						type: 'MAINNET',
					})),
			},
			{
				title: 'Custom Enterprise Chains',
				networks: finalChainsArray
					.filter((chain) => chain.isCustom && !chain.isTestnet)
					?.map((i) => ({
						name: i.chainName,
						value: Number(i.chainId),
						chainId: Number(i.chainId),
						img: i.chainLogo,
						type: 'CUSTOM',
					})),
			},
			{
				title: 'EVM Testnets',
				networks: finalChainsArray
					.filter((chain) => chain.isTestnet)
					?.map((i) => ({
						name: i.chainName,
						value: Number(i.chainId),
						chainId: Number(i.chainId),
						img: i.chainLogo,
						type: 'EVM_TESTNET',
					})),
			},
		]
			.map((network) => {
				if (!allowedChainIds || (allowedChainIds.length === 0 && defaultAll))
					return network;
				return {
					...network,
					networks: network.networks.filter((net: any) =>
						allowedChainIds.includes(net.chainId),
					),
				};
			})
			.filter((network) => network.networks.length > 0);
	}, [finalChainsArray, allowedChainIds, defaultAll]);

	const [selectedNetworks, setSelectedNetworks] = useState<Network[]>([]);

	useEffect(() => {
		const selected =
			Array.isArray(value) &&
			value.map((chainId) => {
				const found = networks
					.flatMap((group) => group.networks)
					.find((net) => net.chainId === chainId);
				return found
					? found
					: { name: '', value: chainId, chainId, img: '', type: '' };
			});
		setSelectedNetworks(selected);
	}, [value, networks]);

	const handleSelect = (option: Network) => {
		try {
			let newValues;
			if (
				Array.isArray(selectedNetworks) &&
				selectedNetworks?.some((net) => net.chainId === option.chainId)
			) {
				newValues = selectedNetworks?.filter(
					(net) => net.chainId !== option.chainId,
				);
			} else {
				newValues = [...selectedNetworks, option];
			}
			const chainIds = newValues
				.filter((net) => net.type !== 'CUSTOM')
				.map((net) => net.chainId);
			const customChainIds = newValues
				.filter((net) => net.type === 'CUSTOM')
				.map((net) => net.chainId);
			setSelectedNetworks(newValues);
			setValue({ chainIds, customChainIds });
		} catch (e) {
			console.log(e);
		}
	};

	const isSelected = (chainId: number) => {
		return (
			Array.isArray(selectedNetworks) &&
			selectedNetworks.some((net) => net.chainId === chainId)
		);
	};

	return (
		<>
			{isMulti ? (
				<Popover>
					<PopoverTrigger asChild>
						<Button
							variant="outline"
							className="w-full text-muted-foreground justify-between px-3"
							disabled={disabled}
						>
							{!selectedNetworks.length ? (
								<>
									<span>Select Chain</span>
									<i className="bi-chevron-expand"></i>
								</>
							) : (
								<div className="hidden space-x-1 lg:flex truncate">
									{selectedNetworks.length > 2 ? (
										<>
											{selectedNetworks
												.slice(0, 2)
												.map((option) => (
													<Badge
														variant="secondary"
														key={option.chainId}
														className="rounded-sm px-1 font-normal truncate"
													>
														<ChainBadge
															chainId={option.chainId}
															showAdditionalChains
															fetchEnterpriseChains={
																fetchEnterpriseChains
															}
														/>
													</Badge>
												))}
											<Badge
												variant="secondary"
												className="rounded-sm px-1 font-normal"
											>
												+{selectedNetworks.length - 2}{' '}
												selected
											</Badge>
										</>
									) : (
										selectedNetworks?.map((option) => (
											<Badge
												variant="secondary"
												key={option.chainId}
												className="rounded-sm px-1 font-normal truncate"
											>
												<ChainBadge
													chainId={option.chainId}
													showAdditionalChains
													fetchEnterpriseChains={
														fetchEnterpriseChains
													}
												/>
											</Badge>
										))
									)}
								</div>
							)}
						</Button>
					</PopoverTrigger>
					<PopoverContent
						align="start"
						className="p-0"
						withoutPortal={withoutPortal}
					>
						<Command>
							<CommandInput
								placeholder={'Select Chains'}
								className="p-2 border-none focus:ring-0 focus:outline-none rounded-lg"
							/>
							<Separator />
							<CommandList className="">
								<CommandEmpty>No results found.</CommandEmpty>
								{networks?.map((network) => (
									<CommandGroup key={network.title}>
										<CommandItem className="hover:!bg-transparent cursor-default">
											{network.title}
										</CommandItem>
										{network.networks.map((option) => {
											const selected = isSelected(
												option.chainId,
											);
											return (
												<CommandItem
													key={option.chainId}
													onSelect={() =>
														handleSelect(option)
													}
													className="cursor-pointer"
												>
													<div
														className={cn(
															'mr-2 flex size-4 items-center justify-center rounded-sm border border-primary',
															selected
																? 'bg-primary text-primary-foreground'
																: 'opacity-50 [&_svg]:invisible',
														)}
													>
														{selected && (
															<CheckIcon
																className="size-4"
																aria-hidden="true"
															/>
														)}
													</div>
													{option.img && (
														<>
															<img
																src={option.img}
																alt=""
																className="h-5 w-5 me-2 rounded-lg object-cover"
															/>
															<span>
																{option.name}
															</span>
														</>
													)}
												</CommandItem>
											);
										})}
									</CommandGroup>
								))}
								{selectedNetworks?.length > 0 && (
									<>
										<CommandSeparator />
										<CommandGroup>
											<CommandItem
												onSelect={() =>
													setValue({
														chainIds: [],
														customChainIds: [],
													})
												}
												className="justify-center text-center"
											>
												Clear Selection
											</CommandItem>
										</CommandGroup>
									</>
								)}
							</CommandList>
						</Command>
					</PopoverContent>
				</Popover>
			) : null}
			{error ? (
				<p className="text-destructive text-xs mt-1">{errorMsg || error}</p>
			) : null}
		</>
	);
}
