import { IChain } from '@/hooks/useGetSupportedChains';
import { queryClient } from '@/lib/react-query';
import { createCustomChain, uploadFile } from '@/services/utility.service';
import { handleErrorMessage } from '@/utils/notifications';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { useCreateCampaign } from './useCreateCampaign';

export interface IAddCustomChain {
	chainId: number;
	chainName: string;
	chainLogo: string;
	chainLogoFile: File;
	imagePosition?: {
		x: number;
		y: number;
	};
}

const initialValue: IAddCustomChain = {
	chainId: 0,
	chainName: '',
	chainLogo: '',
	chainLogoFile: null,
	imagePosition: {
		x: 0,
		y: 0,
	},
};
const useAddCustomChain = ({ setShow }) => {
	const [formFields, setFormFields] = useState<IAddCustomChain>(initialValue);
	const [formErrors, setFormErrors] = useState<any>({});
	const [isUploading, setIsUploading] = useState(false);

	const existingChains: IChain[] = queryClient.getQueryData(['chains', true]);

	const { setDetails } = useCreateCampaign();

	const resetForm = () => {
		setFormFields(initialValue);
		setFormErrors({});
		setShow(false);
	};

	const addChainMutation = useMutation({
		mutationFn: createCustomChain,
		onSuccess: async () => {
			queryClient.invalidateQueries({
				queryKey: ['chains', true],
			});
			resetForm();
		},
		onError: (error) => {
			handleErrorMessage(error);
		},
	});

	const validateForm = () => {
		const errors: any = {};
		if (!formFields.chainLogo) {
			errors.chainLogo = 'Chain logo is required';
		}
		if (!formFields.chainName) {
			errors.chainName = 'Chain name is required';
		}

		if (
			formFields.chainId &&
			existingChains?.find((chain) => chain.chainId === formFields.chainId)
		) {
			errors.chainId = 'This id is already associated with another chain';
		}

		setFormErrors(errors);

		return Object.keys(errors).length === 0;
	};

	const addNewCustomChain = () => {
		if (!validateForm()) return;

		const payload = {
			...(formFields.chainId && { chainId: formFields.chainId }),
			chainName: formFields.chainName,
			chainLogo: formFields.chainLogo,
		};
		if (formFields.chainLogoFile && formFields.chainLogo.includes('blob:')) {
			setIsUploading(true);
			toast.promise(
				Promise.all([
					uploadFile(formFields.chainLogoFile).then((res) => {
						setFormFields((prev) => {
							return {
								...prev,
								chainLogo: res,
							};
						});
						setIsUploading(false);
						addChainMutation.mutateAsync({
							...payload,
							chainLogo: res,
						});
					}),
				]),
				{
					loading: 'Uploading chain logo...',
				},
			);
		} else {
			addChainMutation.mutateAsync(payload);
		}
		setDetails((prev) => ({
			...prev,
			network: formFields.chainId,
			isCustomChain: true,
		}));
	};

	useEffect(() => {
		setFormErrors({});
	}, [formFields]);

	return {
		formFields,
		setFormFields,
		formErrors,
		setFormErrors,
		resetForm,
		addNewCustomChain,
		isPending: addChainMutation.isPending || isUploading,
	};
};

export default useAddCustomChain;
